<template>
  <div class="item-images">
    <div class="images-thumbnails" v-if="visible">
        <div v-for="img in images" class="images-thumbnails" v-bind:key="img.id">
          <img v-on:click="setDefault(img)" v-bind:src="image(img)" />
        </div>
    </div>
    <div class="images-image" v-if="visible">
      <img v-bind:src="image(visible)" />
    </div>
    <div v-if="!visible">
      <ImageWrapper width="100%" src="https://img.liberamerkato.local/not-found.800x600.jpg" alt="No hay imagenes" size="800x600"></ImageWrapper>
    </div>
  </div>
</template>

<script>
export default {
  id: "listing-images",
  name: 'ListingImages',
  data() {
    return {
      visible: false
    }
  },
  props: {
    images: {
      type: Array,
      /*validator(value) {
        console.log('validator', value);
      },*/
      /*default(value) {
        console.log('default', value);
      }*/
    }
  },
  methods: {
    image(img) {
      return this.$config.IMG_URL + img.path;
    },
    setDefault(image) {
      this.visible = image;
    }
  },
  mounted() {
    //console.log('mounted',this.images);
    if (this.images.length === 0) {
      var path = '../not-found.800x600.jpg';
      //console.log(path);
      this.images.push({path: path});
    } else {
      this.visible = this.images[0];
    }
    return false;
  }
}
</script>
<style>
.item-images {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 5fr;
}
.images-thumbnails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.images-thumbnails img {
  width: 100%;
}
.images-image img {
  width: 100%;
}
</style>