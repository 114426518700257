<template>
  <div id="hamburger">
      <a @click="toggle"><i class="bi bi-list"></i></a>
  </div>
</template>

<script>
import HamburgerMenu from "./HamburgerMenu.vue"

export default {
  name: "Hamburger",
  methods: {
    toggle() {
      console.log('hamb', HamburgerMenu)
      this.$openModal({
        component: HamburgerMenu,
        cssClass: ["open-on-left", "no-padding"],
      })
    }
  }
}
</script>