<template>
  <div id="listings-edit">
    <h1>Editar publicación</h1>
    <listing-add-edit-form-basic v-if="item" @save="save" :listing="item">
    </listing-add-edit-form-basic>
  </div>
</template>

<script lang="js">
import ListingAddEditFormBasic from '../components/ListingAddEditFormBasic.vue'
import ListingImagesModal from "./ListingImagesModal.vue"
import { useListingStore } from '../stores/listings';

export default {
  name: "ListingEdit",
  id: "listing-edit",
  components: {
    ListingAddEditFormBasic,
    ListingImagesModal
  },
  data() {
    return {
      item: false
    }
  },
  methods: {
    updateCategory(evt) {
      console.log('updateCat', evt);
    },
    save() {
      this.$router.push({name:'listings-own'});
    }
  },
  mounted() {
    const self = this;
    const store = useListingStore();
    store.load(this.$route.params.id).then(function(item) {
      self.item = item;
    });
  }
}
</script>