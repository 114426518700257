<template>
  <div class="">
    <h1>Preguntas realizadas</h1>
    <ul class="listing-question-list" v-if="questions">
      <li v-for="question in questions" v-bind:key="question.id" class="listing-question-item">
        <div class="question">
          {{question.question}}
        </div>
        <div class="answer">
          <div v-if="question.reply">
            <blockquote>{{question.reply.response}}</blockquote>
          </div>
          <div v-else>
            No hay respuesta
          </div>
        </div>
        <div class="item">
          <div class="dt">Item</div>
          <router-link :to="{name:'listing-view', params: {id: question.listing.id}}">
          {{question.listing.title}}
          </router-link>
        </div>
        <div class="created">
          <div class="dt">Preguntado el</div>
          {{question.created_at}}
        </div>
        <div class="replied">
          <div class="dt">Respondido el</div>
          <div v-if="question.reply">
            {{question.reply.created_at}}
          </div>
          <div v-else>
            --
          </div>
        </div>
      </li>
    </ul>
    <div v-else>
      <p>No has hecho preguntas</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionsAsked",
  data() {
    return {
      questions: []
    }
  },
  mounted() {
    var self = this;
    this.$store.dispatch('questions/asked').then(function(q){
      self.questions = q;
    })
  }
};
</script>
<style>
.listing-question-item {
  display: grid;
  background: #302e2c;
  gap: 1rem;
  padding: 1rem;
  grid-template-columns: 3fr 1fr 1fr;
  margin-bottom: 1rem;
}
.listing-question-item .question {
  grid-column: 1/4;
  grid-row: 1;
  padding-bottom: 1rem;
  border-bottom: 1px solid #282520;
}
.listing-question-item .answer {
  grid-column: 1/4;
  grid-row: 2;
  padding-bottom: 1rem;
  border-bottom: 1px solid #282520;
}
.listing-question-item .item {
  grid-column: 1;
  grid-row: 3;
}
.listing-question-item .created {
  grid-column: 2;
  grid-row: 3;
}
.listing-question-item .replied {
  grid-column: 3;
  grid-row: 3;
}

</style>