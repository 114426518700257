<template>
  <i v-on:click="toggleVisibility" :class="icons" class="pointer bi bi-eye-slash"></i>
</template>

<script>
export default {
  props: {
    input: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      icons: {}
    }
  },
  methods: {
    toggleVisibility() {
      //console.log(this.$parent.$parent.$refs.control);
      //var input = document.getElementById(this.input);
      var input = this.$parent.$parent.$refs.control;
      if (input.type === 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
      this.icons['bi-eye-slash'] = input.type == 'password';
      this.icons['bi-eye'] = input.type == 'text';
    }
  }
}
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>