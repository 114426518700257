<template>
  <div id="listing-image-list" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button v-for="(img, _) in images" v-bind:key="img.id" type="button" data-bs-target="#listing-image-list" v-bind:data-bs-slide-to="_" class="active" aria-current="true" v-bind:aria-label="'Slide ' + _ "></button>
    </div>
    <div class="carousel-inner">
      <div v-for="img in images"
        class="carousel-item" v-bind:key="img.id">
        <img class="d-block w-100" v-bind:src="source(img)" />
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#listing-image-list" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#listing-image-list" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ListingImagesBootstrap',
  data() {
    return {
      active: false
    }
  },
  props: {
    images: {
      type: Array
    }
  },
  mounted() {
    //var element = document.getElementById('listing-image-list');
    //var c = bootstrap.Carousel.getInstance(element);
    //c.to(0);
    var c = document.getElementsByClassName('carousel-item');
    if (c.length) {
      c[0].classList.add('active');
    }
  },
  methods: {
    source(img) {
      return this.$config.IMG_URL + img.path
    }
  }
}
</script>