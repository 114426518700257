import NewPassModal from "./modals/NewPassModal.vue"
import RegisterForm from "./component/RegisterForm.vue"
import RegisterVerifyModal from "./modals/RegisterVerifyModal.vue"
import ResetModal from "./modals/ResetModal.vue"
import VerifyResetCodeModal from "./views/VerifyResetCodeModal.vue";
import UserAppletSidebar from "./component/UserAppletSidebar.vue";
import UserProfile from "./views/UserProfile.vue";
import { useAuthStore } from "./stores/auth";
//import profileStore from "./stores/profile";
//import notificationStore from "./stores/notification";
import { useSettingsStore } from "./stores/settings";
import ResetView from "./views/ResetView.vue";
import RegisterView from "./views/RegisterView.vue";

import LoginFormModal from "./modals/LoginFormModal.vue";

export default {
  installed: false,
  install(app, options) {
    
    if (typeof options == 'undefined') {
        console.warn('AuthPlugin necesita declarar options (store, router)')
    };

    if (this.installed) return;

    //app.config.globalProperties.$systemArea.add(UserApplet);
    app.component('user-applet-sidebar', UserAppletSidebar);
    //app.component('user-applet', UserApplet);
    
    //app.config.globalProperties.$store.registerModule("auth", store);
    //options.store.registerModule("auth", store);
    //options.store.registerModule("profile", profileStore);
    //options.store.registerModule("notification", notificationStore);
    //options.store.registerModule("settings", settingsStore);

    const routes = [
      { path: '/profile', component: UserProfile },
      { path: '/login', name: "auth-login", component: LoginFormModal },
      { path: '/reset', name:"auth-reset", component: ResetView },
      { path: '/register', name: "auth-register", component: RegisterView }
    ];

    routes.forEach(function(route) {
      options.router.addRoute(route);
    });

    const auth = {
      loginModal: function() {
        return app.config.globalProperties.$openModal({
          component: LoginFormModal,
        });
      },
      registerModal: function() {
        return app.config.globalProperties.$openModal({
          component: RegisterForm
        });
      },
      resetModal: function() {
        return app.config.globalProperties.$openModal({
          component: ResetModal,
        });
      },
      newPassModal: function({ props }) {
        return app.config.globalProperties.$openModal({
          component: NewPassModal,
          props: props
        });
      },
      verifyResetCode: function ({ props }) {
        return app.config.globalProperties.$openModal({
          component: VerifyResetCodeModal,
          props: props
        });
      },
      verifyModal: function({ props }) {
        return app.config.globalProperties.$openModal({
          component: RegisterVerifyModal,
          props: props
        });
      },
      isAuthenticated: function() {
        const store = useAuthStore();
        return store.isAuthed;
      },
      isAuth: function() {
        return this.isAuthenticated();
      },
      isAuthed: function() {
        return this.isAuthenticated();
      }
    };

    options.router.beforeEach((to, from, next) => {
      if (to.name === "home") {
        if (from.name !== null) {
          //options.store.dispatch("apps/activate", to.meta.app);
        }
      }
    
      if (from.meta.app !== to.meta.app) {
        // Si activo lleva a la ruta "default"
        //console.log(router);
        //options.store.dispatch("apps/change", to.meta.app);
      }
    
      if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log("requiresAuth");
        if (auth.isAuthenticated()) {
          //console.log("Authentincated");
          next();
        } else {
          //console.log("Not authentincated");
          let instance = auth.loginModal();
          instance
            .then(function(message) {
              //console.log("auth.loginModal:OK", message);
              next();
            })
            .catch(function(message) {
              //console.log("auth.loginModal:ERR", message);
              next(false);
            });
        }
      } else {
        next();
      }
    });

    options.http.interceptors.request.use(config => {
      let t = localStorage.getItem("token") || null;

      if (t) {
        config.headers.common["Authorization"] = `Bearer ${t}`;
      }

      return config;
    });

    options.http.interceptors.response.use(response => {
        return response;
      },
      function(error) {
        if (typeof error.response === 'undefined') {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          return new Promise(function(resolve, reject) {
            let instance = app.config.globalProperties.$auth.loginModal();
            let config = error.config;

            instance
              .then(function() {
                let t = localStorage.getItem("token");
                config.headers["Authorization"] = `Bearer ${t}`;
                resolve(options.http.request(config));
              })
              .catch(function(err) {
                reject(err);
              });
          });
        } else {
          return Promise.reject(error);
        }
      }
    );

    app.config.globalProperties.$auth = auth;
    
    this.installed = true;
  }
};

export { UserProfile, useSettingsStore, useAuthStore };