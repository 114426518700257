<template>
  <div class="container">
    <h2>Responder pregunta</h2>
    <blockquote>
      {{question.question}}
    </blockquote>
    <form>
      <fieldset>
        <legend>Escriba la respuesta</legend>
        <textarea class="form-control" v-model="item.response"></textarea>
      </fieldset>
      <div class="button-group">
        <button
          type="button"
          @click="close()"
        >
          Cerrar
        </button>
        <button type="button" class="primary" v-on:click="reply()">Responder</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "QuestionReply",
  id: "question-reply",
  data() {
    return {
      item: {
        response: "",
      },
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    }
  },
  methods: {
    close() {
      this.reject("Closed");
    },
    reply() {
      var params = {
        listing_id: this.question.listing.id,
        question_id: this.question.id,
        data: this.item
      };
      var self = this;
      this.$store.dispatch("listings/questionReply", params).then(function(){
        self.resolve("OK");
      });
      //console.log(this.item);
    },
  },
};
</script>
