<template>
  <div id="hamburger-menu" class="gb">
    <ul class="menu">
      <template v-for="item in menu">
        <li v-if="!item.menu" :key="item.name">
          <router-link v-if="item.route" :to="{ name: item.route.name }">
            <i v-if="item.icon" :class="item.icon"></i>
            <span v-if="item.title">{{ item.title }}</span>
            <span v-if="item.badge" class="badge">{{item.badge}}</span>
          </router-link>
        </li>

        <li v-if="item.menu" :key="item.name">
          <a @click="open(item)">
            <i v-if="item.icon" :class="item.icon"></i>
            <span v-if="item.title">{{ item.title }}</span>
            <span v-if="item.badge" class="badge">{{item.badge}}</span>
          </a>
        </li>
        <!--menu-dropdown @emit="emit" :key="item.name" v-if="item.menu" :item="item"></menu-dropdown-->
      </template>
    </ul>
  </div>
</template>

<script>
import HamburgerSubmenu from "./HamburgerSubmenu.vue"

export default {
  id: "hamburger-menu",
  name: "Hamburger",
  methods: {
    open(item) {
      //console.log(item);
      var self = this;
      this.$openModal({
        component: HamburgerSubmenu,
        props: {
          menu: item.menu,
        },
        cssClass: ['open-on-left', 'no-padding']
      }).then(function(val) {
        if (val === 'close') {
          self.resolve('close');
        }
      })
    }
  },
  computed: {
    menu: function() {
      if (this.$store.state.apps.active === null) {
        console.log("HeaderMenu SIN app activa");
        return [];
      } else {
        let activeApp = this.$store.state.apps.active;
        let menu = activeApp.menu;
        //console.log("HeaderMenu CON app activa", menu, activeApp);
        return menu;
      }
    }
  }
};
</script>

<style>
#hamburger-menu .menu {
  flex-direction: column;
}
</style>