<template>
  <div id="header-menu">
    <menu-base :menu="menu"></menu-base>
  </div>
</template>

<script>
import MenuBase from "./MenuBase.vue";
import { useAppsStore } from "@linkedcode/app-store-plugin/";

export default {
  id: "navbar-menu",
  name: "NavbarMenu",
  data: function () {
    //console.log(this);
    return {
      selected: null,
    };
  },
  components: {
    MenuBase,
  },
  methods: {
    select(id) {
      if (this.selected === id) {
        this.selected = null;
      } else {
        this.selected = id;
      }
    },
  },
  computed: {
    menu: function () {
      const store = useAppsStore(this.$pinia);
      //console.log(store);
      if (store.active === null) {
        console.log("HeaderMenu SIN app activa");
        return [];
      } else {
        let activeApp = store.active;
        let menu = activeApp.menu;
        //console.log("HeaderMenu CON app activa", menu, activeApp);
        return menu;
      }
    },
    brand: function () {
      return "Marca";
    },
  },
};
</script>
