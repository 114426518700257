import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', {
  state: () => ({
      modals: []
  }),
  getters: {
    getModals(state) {
      return state.modals;
    },
    level(state) {
      return state.modals.length + 1;
    }
  },
  actions: {
    openModal(opts) {
      const self = this;

      if (typeof opts.cssClass === 'undefined') {
        opts.cssClass = ["open-on-right"];
      }

      document.body.classList.add('layer-modal-open');

      return new Promise(function(resolve, reject) {
        console.log("Creando promesa");

        opts.component.methods._resolve = function(payload) {
          console.log("resolve layermodal", opts.component.name, payload);
          self.hideModal(opts);
          resolve(payload);
        };

        opts.component.methods._reject = function(payload) {
          console.log("_reject layermodal", opts.component.name, payload);
          self.hideModal(opts);
          reject(payload);
        };

        //if (typeof opts.component.methods.reject === 'undefined') {
        opts.component.methods.reject = function (reason) {
          opts.component.methods._reject(reason)
        }/**/

        opts.component.methods.resolve = function (reason) {
          opts.component.methods._resolve(reason)
        }/**/
        //}

        /*if (typeof opts.component.methods.esc === 'undefined') {
          opts.component.methods.esc = function () {
            opts.component.methods._reject('ESC')
          }
        }

        if (typeof opts.component.methods.cancel === 'undefined') {
          opts.component.methods.cancel = function (text) {
            opts.component.methods._reject('Cancelado ' + text)
          }
        }*/
        self.addModal(opts);
      });
    },
    addModal(modal) {
      modal.cssClass.push(`level${this.level}`);
      //console.log("storeModal adding", modal);
      this.modals.push(modal);
      this.updateWidths();
    },
    escape() {
      if (this.modals.length) {
        console.log("store escape, Click back");
        this.modals[this.modals.length - 1].component.methods.reject('Click background');
      }
    },
    hideModal(modal) {
      console.log("store hideModal:", modal.component.name);
      this.modals.forEach(function(item, idx) {
        if (item.component.name === modal.component.name) {
          console.log("store hideModal encontrado");
          this.modals.splice(idx, 1);
        }
      }, this);
      
      this._afterClose();
    },
    close() {
      console.log('close');
      this.modals.splice(this.modals.length - 1, 1);
      this._afterClose();
    },
    _afterClose() {
      if (this.modals.length === 0) {
        document.body.classList.remove('layer-modal-open');
      } else {
        this.updateWidths();
      }
    },
    updateWidths(data) {
      //console.log('updateWidths')

      var firstSet = false;
      this.modals.forEach(function(m, idx){
        if (m.width) {
          firstSet = m.width;
        }
      });

      if (!firstSet) {
        firstSet = 50;
      }

      var q = this.modals.length;

      this.modals.forEach(function(m) {
        q--;
        let value = firstSet + (5 * q);
        if (!m.style) {
          m.style = {};
        }
        m.style.width = `${value}%`;
      });
    },
  }
});
