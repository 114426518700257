<template>
  <div class="section-title">
    <h2>{{ title }}</h2>
    <div class="section-title-actions" v-if="this.$slots.actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  }
};
</script>
<style>
.section-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #555;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}
</style>