<template>
  <div class="modal-title">
    <h2>{{ title }}</h2>
    <div class="modal-title-actions" v-if="this.$slots.actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script lang="js">
export default {
  name: "ModalTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  }
};
</script>