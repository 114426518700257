<template>
  <div class="login-form-modal">
    <h2>Ingresar</h2>
    <FormWrapper :error="error">
      <FormRowWrapper>
        <FormEmailWrapper 
          label="Correo electrónico"
          data-autocomplete="username"
          autocomplete="off"
          ref="email"
          v-model="email"
        />
      </FormRowWrapper>
      <FormRowWrapper>
        <FormPasswordWrapper ref="password" label="Contraseña" :isLogin="true" v-model="password">
        </FormPasswordWrapper>
      </FormRowWrapper>

      <FormActionsWrapper>
        <button type="button" @click="cancelar()">Cancelar</button>
        <button type="button" @click="login()" class="primary">Iniciar Sesion</button>
      </FormActionsWrapper>

      <h3>¿Olvido su clave?</h3>
      <p>Si no recuerda su clave, <a v-on:click="reset()">resetee su clave</a>.</p>
    </FormWrapper>
  </div>
</template>
<script>
import ResetModal from "../modals/ResetModal.vue";
import { FormWrapperMixin } from "@linkedcode/form-wrapper";
import { useAuthStore } from "../stores/auth";

export default {
  name: "LoginForm",
  id: "login-form",
  mixins: [FormWrapperMixin],
  components: {
    ResetModal
  },
  data() {
    return {
      el: "login",
      errors: [],
      email: "",
      password: ""
    };
  },
  methods: {
    cancelar() {
      //this.cancel("C");
      this.reject('F');
    },
    login() {
      if (this.validate()) {
        this.submit();
      }
    },
    submit() {
      var self = this;
      const store = useAuthStore();
      store.login(this.email, this.password)
        .then(function(jwt) {
          console.log('login', jwt);
          self.resolve(jwt);
        }).catch(function(err) {
          //console.log('login', err);
          
          if (err.response.status == 403) {
            var self2 = self;
            self2.$auth._verifyModal({
              props: {
                email: self.email,
                password: self.password,
                code: ""
              }
            }).then(function(jwt) {
              self2.resolve(jwt);
              self.resolve(jwt);
            });
          } else {
            console.log('err', err)
            self.error = {
              title: err.response.data.error,
              detail: err.response.data.error_description,
              code: 401
            };
          }
        });;
    },
    reset() {
      var props = {};
      if (this.email != "") {
        props.email = this.email;
      }
      const self = this;
      console.log("Abriendo resetmodal");

      this.$openModal({
        component: ResetModal,
        algo: 'algo',
        props: props
      }).then(function(res) {
        console.log("ResetModal", "resolve", res);
        self.resolve(res);
      }).catch(function(res) {
        console.log("ResetModal.catch", "reject", res);
        //self.reject(res);
      });
    }
  }
};
</script>