import Toast, { POSITION, useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

export default {
  install(app, options) {

    app.use(Toast, {
      position: POSITION.BOTTOM_LEFT
    });

    const toast = useToast();

    const notifications = {
      success(msg) {
        toast.success(msg);
      },
      error(msg) {
        toast.error(msg);
      },
      info(msg) {
        toast.info(msg);
      },
    }

    app.config.globalProperties.$notifications = notifications;
  }
};
