<template>
  <header>
    <hamburger></hamburger>
    <div id="brand">
      <router-link class="navbar-brand" to="/">liberamerkato</router-link>
    </div>
    <navbar-menu />
    <system-area></system-area>
  </header>
  <router-view class="main-wrapper" />
  <footer>
    liberamerkato
  </footer>
  <layer-modal></layer-modal>
</template>