import axios from "axios";
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'

const http = axios.create();

const AxiosPlugin = {
  installed: false,
  install(app, options) {
    if (this.installed) {
      return;
    }

    http.defaults.baseURL = options.api.url;
    loadProgressBar({}, http);

    options.pinia.use(() => ({ $http: http }));

    this.installed = true;
  }
};

export { http, AxiosPlugin };
