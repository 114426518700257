<template>
  <div id="system-area">
    <component v-for="applet in applets" :key="applet" :is="applet"></component>
  </div>
</template>

<script>
import { useSystemAreaStore } from "./store";

export default {
  name: "SystemArea",
  computed: {
    visible() {
      //return this.$store.getters['auth/isAuthed'];
      return true;
    },
    applets() {
      const store = useSystemAreaStore(this.$pinia);
      const apps = store.applets
      var show = [];
      //console.log(apps);
      apps.forEach(function(applet, i) {
        //console.log(applet);
        if (applet.requireAuth === true && this.visible) {
          show.push(applet)
        }
        if (applet.requireAuth === false) {
          show.push(applet)
        }
      }, this)
      
      return show;
    }
  }
};
</script>