<template>
  <div class="container">
    <div id="main-content" class="row">
      <div class="col-12">
        <h1>Carrito</h1>
      </div>
      <main class="col-8">
        <div class="shopping-cart"  v-if="cart">
          <div class="row" v-for="item in cart.items" v-bind:key="item.listing_id">
            <div class="col-8">
              {{item.title}}
            </div>
            <div class="col-2">
              {{item.quantity}}
            </div>
            <div class="col-2">
              {{item.symbol}}
              {{item.price}}
            </div>
          </div>
        </div>
      </main>
      <aside class="col-4">
        <div class="row">
          <div class="col-6">Subtotal</div>
          <div class="col-6">{{subtotal}}</div>
        </div>
        <div class="row">
          <div class="col-6">Costo de envío:</div>
          <div class="col-6">{{shipping}}</div>
        </div>
        <div class="row">
          <div class="col-6">Total</div>
          <div class="col-6">{{total}}</div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: "ShoppingCart",
  data() {
    return {
      shipping: 0,
      discount: 0
    }
  },
  computed: {
    cart() {
      return this.$store.state.cart.data;
    },
    subtotal() {
      var subtotal = 0;
      if (this.cart) {
        this.cart.items.forEach(function(item, index){
          subtotal += item.quantity * item.price;
        }, this);
      }
      return subtotal;
    },
    total() {
      return this.subtotal - this.shipping - this.discount;
    }
  },
  methods: {
    checkout() {

    }
  }
}
</script>