<template>
  <div class="auth-aside">
    <h2>¿Ya está registrado?</h2>
    <p>Para ingresar, <router-link :to="{name: 'auth-login'}">inicie sesión</router-link>.
    </p>
    <h2>¿No está registrado?</h2>
    <p>Si aún no está registrado,
      <router-link :to="{name: 'auth-register'}">registrese</router-link>.
    </p>
    <h2>¿Olvidó su clave?</h2>
    <p>Si olvido su contraseña, tendrá que 
      <router-link :to="{name: 'auth-reset'}">resetear su contraseña</router-link>.
    </p>
  </div>
</template>
<script>
export default {
  name: "AuthSidebarView"
}
</script>
<style>
.auth-aside {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>