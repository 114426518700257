<template>
  <li>
    <div class="item-info">
      <div class="thumb">
        <ImageWrapper v-if="item.imageUrl" size="90x90" :src="thumb(item)" />
      </div>
      <div class="title">
        <div class="dt">Titulo</div>
        <!--a href="#" @click="view(item.id)">{{item.title}}</a-->
        <router-link :to="{name:'listing-view',params:{id:item.id}}">{{item.title}}</router-link>
      </div>
      <div class="price">
        <div class="dt">Precio</div>
        {{ item.currency.symbol }} {{ item.price }}</div>
      <div class="dl status">
        <div class="dt">Estado</div>
        <!--div>{{item.status.value}}</div-->
        <listing-status-change :id="item.status" :listingId="item.id"></listing-status-change>
      </div>
      <!--div>
        
      </div-->
      <div class="stock">
        <div class="dt">Stock</div>
        <div>
          {{item.availableQuantity}}
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="button-group" role="group">
        <!--button type="button"
          @click="edit(item.id)"
          class="btn btn-primary"
          >Editar</button-->
        <router-link class="button" :to="{name:'listings-edit', params: {id: item.id}}">Editar</router-link>
        <!--a href="javascript:void(0)" class="button" v-on:click="upload(item.id)">Upload</a-->
        <button type="button" @click="images()">Imágenes</button>
      </div>
    </div>
  </li>
</template>

<script>
/*
:to="{ name: 'listings-edit', params: { id: item.id } }"*/

import ListingStatusChange from "./ListingStatusChange.vue";
import ListingEdit from "../views/ListingEdit.vue"
import ListingDetail from "../views/ListingDetail.vue"
import ListingCrop from "../components/ListingCrop.vue"
import ListingImagesModal from "../views/ListingImagesModal.vue"

export default {
  name: "ListingItem",
  id: "listing-item",
  components: {
    ListingStatusChange
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    thumb(img) {
      return this.$config.IMG_URL + img.imageUrl.replace('.orig.', '.90x90.');
    },
    view(id) {
      this.$openModal({
        component: ListingDetail,
        props: { listingId: id }
      });
    },
    images() {
      var self = this;
      this.$openModal({
        component: ListingImagesModal,
        props: { listing: this.item }
      }).then(function() {
        self.reload();
      }).catch(function(err){
        self.reload();
      });
    },
    reload() {
      this.$store.dispatch('listings/reload', { listing_id: this.item.id })
    },
    toast() {
      this.$store.dispatch("notifications/success", { msg: 'ok' });
    },
    edit(id) {
      var self = this;
      this.$openModal({
        component: ListingEdit,
        props: { id: id }
      }).then(function(a) {
        self.$store.dispatch('listings/reload', { listing_id: id })
      }).catch(function(a) {
        console.log('reject', a);
      });
    },
    upload(id) {
      var self = this;
      this.$openModal({
        component: ListingCrop,
        props: { listingId: id }
      }).then(function(a) {
        console.log(a);
        self.$store.dispatch('listings/reload', { listing_id: id })
      }).catch(function(a) {
        console.log('reject', a);
      });
    }
  }
};
</script>
<style>
.item-info {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 90px 1fr 1fr 1fr;
}
.item-info .thumb {
  grid-column: 1;
  grid-row: 1 / 3;
}
.item-info .title {
  grid-column: 2 / 4;
  grid-row: 1;
}
.item-info .price {
  grid-column: 2;
  grid-row: 2;
}
.item-info .status {
  grid-column: 2;
  grid-row: 3;
}

.item-info .stock {
  grid-column: 4;
  grid-row: 2;
}
</style>