<template>
  <div id="listing-images-modal">
    <h2>Editar imagenes</h2>
    <div v-if="listing">
      <listing-add-edit-images v-if="listing" v-bind:listing="listing"></listing-add-edit-images>
    </div>
  </div>
</template>

<script>
import ListingAddEditImages from "../components/ListingAddEditImages.vue"

export default {
  name: "ListingImagesModal",
  id: "listing-images-modal",
  props: {
    listing: {
      type: Object,
      required: true,
      validator(item) {
        //console.log(item);
        return true;
      }
    }
  },
  components: {
    ListingAddEditImages
  },
  methods: {
    esc() {
      //console.log(this);
      this.reject("Close, cancelled");
    }
  }
}
</script>