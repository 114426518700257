<template>
  <div class="container" v-if="loaded">
    <h1>{{item.title}}</h1>
    <lime-breadcrumb :tree="item.category.breadcrumb"></lime-breadcrumb>
    <div class="listing-detail">
      <listing-images-bootstrap :images="item.images">
      </listing-images-bootstrap>
      <div class="listing-details">
        <div v-html="item.description"></div>
        <!--div class="hstack h3 my-4">
          <div class="price">
            <span class="price-symbol">
              {{item.currency.symbol}}
            </span>{{item.price}}
          </div>
        </div-->
        <h3 class="mt-2">Detalles</h3>
        <dl class="row">
          <dt class="col-4">Condici&oacute;n</dt>
          <dd class="col-8">{{ item.condition.value }}</dd>
          <dt class="col-4">Operacion</dt>
          <dd class="col-8">{{ item.operation.value }}</dd>
          <dt class="col-4">Disponible</dt>
          <dd class="col-8">{{ item.available_quantity }}</dd>
          <dt class="col-4">Precio</dt>
          <dd class="col-8">
            {{item.price}}
            {{item.currency.code}}
          </dd>
        </dl>
        <listing-buy v-bind:item="item"></listing-buy>
        <listing-add-to-cart v-bind:item="item"></listing-add-to-cart>
        
        <h3>Vendedor</h3>
        <dl class="row">
          <dt class="col-4">Publicado por</dt>
          <dd class="col-8">{{ item.user.name }}</dd>
          <dt class="col-4">Ubicacion</dt>
          <dd class="col-8">
            <span v-if="item.address">
            {{ item.address.city.name }}, {{item.address.province.name }}, {{ item.address.country.name }}
            </span>
            <span v-else>
              No se especifico dirección.
            </span>
          </dd>
        </dl>
      </div>
    </div>

    <hr/>

    <listing-questions v-bind:item="item"></listing-questions>
  </div>
</template>

<script>
//import LimeBreadcrumb from "./LimeBreadcrumb.vue"
import ListingQuestions from "./ListingQuestions.vue"
import ListingBuy from "../components/ListingBuy.vue"
import ListingAddToCart from "../components/ListingAddToCart.vue"
import ListingImagesBootstrap from '../components/ListingImagesBootstrap.vue';
import LimeBreadcrumb from '../components/LimeBreadcrumb.vue';
//import ListingBid from "./ListingBid.vue"
export default {
  name: "ListingDetail",
  id: "listing-detail",
  data() {
    return {
      loaded: false,
      id: this.listingId
    };
  },
  computed: {
    isAuthed() {
      return this.$store.getters['auth/isAuthed'];
    }
  },
  props: {
    listingId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    item() {
      return this.$store.state.listings.selected;
    },
  },
  mounted() {
    /*if (this.$route.name == "listing-detail") {
      this.id = this.$route.params.id;
    }*/
    this.load(this.listingId);
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == "listing-view") {
      this.load(to.params.id);
    }
    next();
  },
  methods: {
    load(id) {
      var self = this;
      this.$store.dispatch("listings/load", { id: id }).then(function(){
        self.loaded = true;
      });
    },
  },
  components: {
    //LimeBreadcrumb,
    ListingBuy,
    //ListingBid,
    ListingQuestions,
    ListingImagesBootstrap,
    ListingAddToCart,
    LimeBreadcrumb
  },
};
</script>

