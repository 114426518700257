<template>
  <div class="listing-view" v-if="loaded">
    <h1>{{item.title}}</h1>

    <lime-breadcrumb :tree="item.category.breadcrumb"></lime-breadcrumb>

    <listing-images v-bind:images="item.images"></listing-images>

    <div class="listing-details">
      <div>
        <h3>Descripcion</h3>
        <div v-html="item.description"></div>
      </div>
      <div>
        <h3>Detalles</h3>
        <dl>
          <dt>Condici&oacute;n</dt>
          <dd>{{ item.condition.value }}</dd>
          <dt>Operacion</dt>
          <dd>{{ item.operation.value }}</dd>
          <dt>Disponible</dt>
          <dd>{{ item.available_quantity }}</dd>
          <dt>Precio</dt>
          <dd>
            {{item.price}}
            {{item.currency.code}}
          </dd>
        </dl>
        <!--listing-add-buy :cart="false" v-bind:item="item"></listing-add-buy-->

      </div>
      
      <listing-bid v-if="item.operation.value === 'Auction'" v-bind:item="item"></listing-bid>

      <div>
        <h3>Vendedor</h3>
        <dl>
          <dt>Publicado por</dt>
          <dd>{{ item.user.name }}</dd>
          <dt>Ubicacion</dt>
          <dd>
            <span v-if="item.address">
              {{ item.address.city.name }}, {{item.address.province.name }}, {{ item.address.country.name }}
            </span>
            <span v-else>
              No se especifico dirección.
            </span>
          </dd>
        </dl>
      </div>
    </div>

    <!--listing-questions v-bind:item="item"></listing-questions-->

    <!--section class="author mt-4">
      <div class="section-header">
        <div class="section-title">
          <h2>Autor</h2>
        </div>
      </div>
      <div class="section-content">
       
      </div>
    </section-->
  </div>
</template>

<script lang="js">
//import LimeBreadcrumb from "./LimeBreadcrumb.vue"
import ListingQuestions from "./ListingQuestions.vue"
//import ListingBuy from "./ListingBuy.vue"
//import ListingAddToCart from "./ListingAddToCart.vue"
import ListingAddBuy from "../components/ListingAddBuy.vue"
import ListingImagesBootstrap from '../components/ListingImagesBootstrap.vue';
import ListingImages from '../components/ListingImages.vue';
import LimeBreadcrumb from '../components/LimeBreadcrumb.vue';
import ListingBid from "../components/ListingBid.vue"
import { useListingStore } from "../stores/listings";
import { useAuthStore } from "@linkedcode/auth";

export default {
  name: "ListingDetail",
  id: "listing-detail",
  data() {
    return {
      loaded: false,
      id: this.listingId
    };
  },
  computed: {
    isAuthed() {
      const store = useAuthStore();
      return store.isAuthed;
    }
  },
  computed: {
    item() {
      const store = useListingStore();
      return store.selected;
    },
  },
  mounted() {
    /*if (this.$route.name == "listing-detail") {
      this.id = this.$route.params.id;
    }*/
    this.load(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == "listing-view") {
      this.load(to.params.id);
    }
    next();
  },
  methods: {
    load(listintId) {
      const self = this;
      const store = useListingStore();
      store.load(listintId).then(function(){
        self.loaded = true;
      });
    },
  },
  components: {
    //LimeBreadcrumb,
    //ListingBuy,
    ListingBid,
    ListingQuestions,
    ListingImagesBootstrap,
    ListingImages,
    ListingAddBuy,
    LimeBreadcrumb
  },
};
</script>

