<template>
  <div id="register-modal" class="register-form-modal">
    <h2>Registro</h2>
    <FormWrapper :error="error">
      <FormRowWrapper>
        <!-- :apiErrors="emailErr" -->
        <FormEmailWrapper label="Correo electronico" ref="email" v-model="formData.email">

        </FormEmailWrapper>
      </FormRowWrapper>

      <FormRowWrapper>
        <FormPasswordWrapper label="Contraseña" ref="pass" v-model="formData.password">
        </FormPasswordWrapper>
      </FormRowWrapper>

      <FormActionsWrapper>
        <button type="button" v-on:click="cancel()">
          Cancelar
        </button>
        <button type="button" v-on:click="register()" class="primary">
          Registro
        </button>
      </FormActionsWrapper>
    </FormWrapper>
  </div>
</template>
<script>

import { FormWrapperMixin } from "@linkedcode/form-wrapper";
import { useAuthStore } from "../stores/auth";

export default {
  name: "RegisterModal",
  mixins: [ FormWrapperMixin ],
  id: "register-modal",
  data: function () {
    return {
      formData: {
        email: "",
        password: "",
      }
    };
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    register() {
      if (this.validate()) {
        this.submit();
      }
    },
    submit() {
      this.resetErrors();
      let self = this;
      const store = useAuthStore();
      console.log("register form");
      store.register(self.formData.email, self.formData.password)
        .then(function (msg) {
          console.log(msg);
          self.resolve(self.formData);
        })
        .catch(function (err) {
          self.error = err;
        });
    }
  }
}
</script>