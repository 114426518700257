<template>
  <div class="container">
    <h2>Imagen</h2>
    <div class="cropper">
      <cropper
        :src="image.src"
        ref="cropper"
        :canvas="{
          minHeight: 0,
          minWidth: 0,
          maxHeight: 600,
          maxWidth: 800,
          height: 600,
          width: 800,
        }"
        :stencil-props="{
          handlers: {},
          movable: false,
          scalable: false,
        }"
        @change="onChange"
        :debounce="false"
        :stencil-size="{
          width: 400,
          height: 300,
        }"
        image-restriction="stencil"
      />

      <!--h2>Preview</h2>

      <preview
        :width="120"
        :height="120"
        :image="result.image"
        :coordinates="result.coordinates"
      /-->
    </div>
    <div id="drop-zone" class="dropZoneCss" v-if="!this.loaded"
      @drop.prevent="dropImage" @dragenter.prevent="onDragEnter" @dragover.prevent="onDragEnter" @dragleave="onDragLeave">
      Arrastrar imagen
    </div>
    <div class="button-group">
        <button
          type="button"
          class=""
          @click="cancel"
        >
          Cerrar
        </button>
        <button type="button" class="primary" @click="$refs.file.click()">
          <input
            type="file"
            ref="file"
            @change="loadImage($event)"
            accept="image/*"
          />
          Cargar imagen
        </button>

        <button v-if="loaded" type="button" class="primary" @click="uploadImage()">Guardar</button>
    </div>
  </div>
</template>

<style>
.cropper {
  margin-bottom: 1rem;
}
button > input {
  display: none;
}
.dragOver {
  background: red;
}
.dropZoneCss {
  padding: 2rem;
  border: 1px solid #302e2c;
  margin-bottom: 1rem;
}
.dropZoneCss:hover {
  background: #302e2c;
}
</style>

<script>
import { Cropper, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { useListingStore } from "../stores/listings";

export default {
  id: "listing-crop",
  name: "ListingCrop",
  components: {
    Cropper,
    Preview,
  },
  props: {
    listingId: {
      type: Number,
    },
  },
  data() {
    return {
      dropZoneCss: {

      },
      image: {
        src: null,
        type: null,
      },
      result: {
        coordinates: null,
        image: null,
      },
      loaded: false
    };
  },
  methods: {
    onDragEnter() {
      this.dropZoneCss.dragOver = true;
    },
    onDragLeave() {
      this.dropZoneCss.dragOver = false;
    },
    checkDrop(e) {
      //console.log(e);
      e.preventDefault()
    },
    dropImage(ev) {
      //console.log(e.dataTransfer);
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // Si los elementos arrastrados no son ficheros, rechazarlos
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
          console.log(file);
          const blob = URL.createObjectURL(file);

          const reader = new FileReader();
          reader.onload = (e) => {
            // Note: arrow function used here, so that "this.image" refers to the image of Vue component
            var mime = getMimeType(e.target.result, file.type);
            //console.log(files[0].type);
            //console.log(mime);
            this.image = {
              // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
              src: blob,
              // Determine the image type to preserve it during the extracting the image from canvas:
              type: mime,
            };
          };
          reader.readAsArrayBuffer(file);
        }
      }
      this.loaded = true;
      ev.dataTransfer.items.clear();
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type);
    },
    cancel() {
      this.image = {
        src: "",
        type: ""
      };
      this.reject("Cerrado");
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      };
    },
    uploadImage() {
      var self = this;
      console.log(this.$refs.cropper.getResult());
      const { canvas } = this.$refs.cropper.getResult();
      const store = useListingStore();

      if (canvas) {
        store.upload(this.listingId, canvas).then(function() {
          self.$notifications.success("Subida correcta");
          self.resolve("OK");
        })
      }
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          var mime = getMimeType(e.target.result, files[0].type);
          //console.log(files[0].type);
          //console.log(mime);
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: mime,
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
        this.loaded = true;
      }
    },
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      };
    },
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
};

function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}
</script>