import Navbar from "./Navbar.vue";
//import SidebarMenu from "./SidebarMenu.vue"
import MenuBase from "./MenuBase.vue"
import Hamburger from "./Hamburger.vue"

export default {
  installed: false,
  install(app, options) {
    if (this.installed) return;
    app.component("navbar-menu", Navbar);
    //app.component("sidebar-menu", SidebarMenu);
    app.component("menu-base", MenuBase);
    app.component("hamburger", Hamburger);
    this.installed = true;
  }
};
