<template>
  <nav id="apps-menu" v-bind:class="{ border: this.available }">
    <div class="active-app">
      <span class="name">{{ active.name }}</span>
      <button v-on:click="change()" v-if="changeable">
        Cambiar
      </button>
    </div>
    <ul v-if="available">
      <li v-for="app in applications"
          v-bind:key="app.name">
        <a href="#" v-on:click="activate(app.name)">
          {{ app.name }}
        </a>
      </li>
    </ul>
  </nav>
  
</template>

<script src="./script.js"></script>

