<template>
  <div id="listings-own">
    <div class="page-title">
      <h1>Mis publicaciones</h1>
      <div class="button-group">
        <!--button class="btn btn-outline-primary" @click="add()">Publicar</button-->
        <router-link class="button primary" :to="{name:'listings-add'}">Publicar</router-link>
      </div>
    </div>
    <div class="grid-list-filters">
      <div class="dl">
        <div class="dt">Texto</div>
        <input type="text" v-model="filters.text" />
      </div>
      <div class="dl">
        <div class="dt">Precio minimo</div>
        <input type="number" v-model="filters.min" />
      </div>
      <div class="dl">
        <div class="dt">Precio maximo</div>
        <input type="number" v-model="filters.max" />
      </div>
      <div class="dl">
        <div class="dt">Estado</div>
        <select v-model="filters.status">
          <option value="">- Seleccione -</option>
          <option value="active">Activo</option>
          <option value="inactive">Inactivo</option>
          <option value="paused">Pausado</option>
        </select>
      </div>
      <div class="dl actions">
        <div class="dt">Acciones</div>
        <div class="button-group">
          <button class="" @click="reset">Limpiar</button>
          <button class="primary" @click="filter">Filtrar</button>
        </div>
      </div>
    </div>
    <ul class="grid-list" v-if="items.length">
      <listing-item-own v-for="item in items" v-bind:key="item.id" v-bind:item="item" />
    </ul>
    <div v-else>
      <p>No tienes publicaciones.</p>
    </div>
  </div>
</template>

<script>

import ListingItemOwn from "../components/ListingItemOwn.vue"
import ListingNew from "./ListingNew.vue"
import { useListingStore } from "../stores/listings";

export default {
  data() {
    return {
      store: null,
      items: [],
      filters: {}
    }
  },
  components: {
    ListingItemOwn,
    ListingNew
  },
  watch: {
    $route(to, from) {
      if (from.name != to.name) {
        return;
      }
      this.load();
    }
  },
  methods: {
    filter() {
      this.$router.push({name:'listings-own', query: this.filters});
    },
    reset() {
      this.filters = {};
      this.filter();
    },
    add() {
      var self = this;
      this.$openModal({
        component: ListingNew
      }).then(function(){
        self.load();
      }) ;
    },
    load() {
      console.log('ListingsOwn', 'params', this.$route.query);
      const self = this;
      const store = useListingStore();
      store.own(this.$route.query).then(function(data) {
        self.items = data;
      });
    }
  },
  mounted() {
    this.load();
    this.filters = Object.assign({}, this.$route.query);
  }
};
</script>
