<template>
  <div>
    <div v-if="open" 
      class="backdrop"
      v-bind:class="backdropClass"
      @click="onBgClicked"
    ></div>
      <div
        v-for="modal in modals"
        :class="getClasses(modal)"
        class="layer-modal"
        :style="modal.style"
        :key="modal.component.id"
      >
          <component
            v-bind:is="modal.component.id"
            v-bind="modal.props"
          ></component>
      </div>
  </div>
</template>

<script>
import { useModalStore } from "./../stores/modal";

export default {
  name: "LayerModal",
  data() {
    return {
      props: {},
      store: null
    };
  },
  computed: {
    backdropClass() {
      let cssClass = "";

      if (this.modals.length) {
        cssClass = ` level${this.modals.length}`;
      }

      return cssClass;
    },
    open() {
      return this.modals.length > 0;
    },
    modals() {
      const store = useModalStore();
      return store.getModals;
    },
    level() {
      const store = useModalStore();
      return store.level;
    }
  },
  created() {
    document.addEventListener("keydown", this.onEscapeKeypress);
  },
  methods: {
    
    append(component) {
      this.store.addModal(component);
      
    },
    getClasses(modal) {
      //console.log(modal);
      const css = {};
      if (modal.cssClass) {
        modal.cssClass.forEach(function(cls) {
          css[cls] = true;
        });
      } else {
        
      }

      let level = this.level - 1;
      if (level > 1) {
        css[`max-level-${level}`] = true;
      }

      return css;
    },
    onEscapeKeypress(e) {
      if (e.keyCode === 27) {
        //console.log('LayerModal: onEscapeKeypress');
        this.escape();
      }
    },
    onBgClicked(evt) {
      console.log("onBgClicked");
      this.escape();
    },
    escape() {
      const store = useModalStore();
      store.escape();
    },
    
  },
  unmounted() {
    document.removeEventListener("keydown", this.onEscapeKeypress);
  }
};
</script>
<style>
.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  overflow: hidden;
}
.layer-modal.level1 {
  z-index: 1046;
}
.layer-modal.level2 {
  z-index: 1048;
}
.layer-modal-container {
  display: block;
}
.layer-modal-wrapper {
  position: static;
}
.layer-modal-open {
  overflow: hidden;
}
.open-on-left {
  right: auto;
  left: 0;
  border-right: 2px solid rgb(12, 104, 238);
}
.open-on-right {
  right: 0;
  left: auto;
  border-left: 2px solid rgb(12, 104, 238);
}
.layer-modal {
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 1rem;
  overflow-y: auto;
  background-color: #282520;
  width: 50%;
  min-width: 320px;
}
.layer-modal.level1.max-level-2, .layer-modal.level1.max-level-3 {
  border-left: 2px solid #ccc;
}
.layer-modal.level2.max-level-3 {
  border-left: 2px solid #ccc;
}
.layer-modal-open header a, .layer-modal-open .main-wrapper a {
  color: rgb(33, 37, 41);
}
.backdrop.level1 {
  z-index: 1045;
}
.backdrop.level2 {
  z-index: 1047;
}
.backdrop.level3 {
  z-index: 1049;
}
.backdrop.level4 {
  z-index: 1051;
}
.layer-modal.level2 {
  z-index: 1048;
}
.layer-modal.level3 {
  z-index: 1050;
}
.layer-modal.level4 {
  z-index: 1052;
}
</style>