<template>
  <div class="reset-modal window-modal">
    <h2>Resetear clave</h2>
    <FormWrapper :errors="errors">
      <FormRowWrapper>
        <FormEmailWrapper ref="email" label="Correo electrónico" v-model="email">
        </FormEmailWrapper>
      </FormRowWrapper>
      <FormActionsWrapper>
        <button type="button" v-on:click="cancelar()">
          Cancelar
        </button>
        <button type="button" class="primary" v-on:click="submit()">
          Enviar código
        </button>
      </FormActionsWrapper>
    </FormWrapper>
  </div>
</template>
<script>
import { FormWrapperMixin } from "@linkedcode/form-wrapper";
import { useAuthStore } from "../stores/auth";

export default {
  name: "ResetModal",
  id: "reset-modal",
  mixins: [FormWrapperMixin],
  data() {
    return {
      el: "reset",
      errors: [],
      email: ""
    };
  },
  props: {
    mail: {
      type: String
    }
  },
  methods: {
    cancelar() {
      console.log(this);
      this.reject("Cancelled");
    },
    submit() {
      if (this.validate()) {
        this._submit();
      }
    },
    _submit() {
      this.error = {};
      var self = this;
      const store = useAuthStore();
      store.reset(self.email)
        .then(function (msg) {
          var obj = {
            props: {
              email: self.email
            }
          };
          self.$auth.verifyResetCode(obj)
            .then(function (res) {
              self.resolve(res);
            })
            .catch(function (res) {
              //self.reject(res);
            });
        }).catch(function (error) {
          self.error = error;
        });;
    }
  }
};
</script>
