<template>
  <div class="container">
    <h2>Perfil</h2>
    <user-profile-form @resolve="resolve"></user-profile-form>
  </div>
</template>

<script>
import UserProfileForm from '../component/UserProfileForm.vue';
export default {
  components: { UserProfileForm },
  name: 'UserProfile',
  id: 'settings-user-profile',
  methods: {}
}
</script>