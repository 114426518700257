<template>
  <div class="listing-add-buy">
    <div class="input-group">
      <button class="btn btn-secondary" type="button" @click="dec">-</button>
      <input type="text" readonly class="form-control text-center" v-model="quantity" />
      <button type="button" class="btn btn-secondary" @click="inc">+</button>
    </div>
    <div class="button-group">
      <button type="button" v-if="cart" @click="addToCart" class="primary">Agregar al carrito</button>
      <button type="button" @click="buy" class="primary">Comprar</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      quantity: 1
    }
  },
  props: {
    cart: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    inc() {
      if (this.quantity < this.item.available_quantity) {
        this.quantity++;
      }
    },
    dec() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    buy() {
      var self = this;
      this.$store.dispatch('listings/buy', { 
        listing_id: this.item.id,
        payload: {
          quantity: this.quantity
        }
      }).then(function(){
        self.$router.push({name:'purchases'});
      });
    },
    addToCart() {
      this.$store.dispatch('cart/addToCart', { 
        payload: {
          listing_id: this.item.id,
          quantity: this.quantity
        }
      })
    }
  }
}
</script>
<style>
.listing-add-buy {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  border: 1px solid darkolivegreen;
  margin: 1rem 0;
}
.listing-add-buy .input-group {
  flex-grow: 2;
}
.listing-add-buy .input-group input[type="text"] {
  padding: 1rem;
  margin: 0;
}
</style>