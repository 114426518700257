<template>
  <FormControlWrapper :label="label" :errors="errors">
    <select :value="modelValue" :class="css" v-bind="$attrs" @input="updateModelValue">
      <option v-for="o in options" :value="o.value" :key="o.value">{{ o.name }}</option>
    </select>
    <slot></slot>
  </FormControlWrapper>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';
import FormValueMixin from './FormValueMixin.vue';

export default {
  mixins: [FormValueMixin],
  name: "FormSelectWrapper",
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    nullValue: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    //console.log('required',this.required);
    //console.log(this.$attrs);
  },
  components: { FormControlWrapper }
};
</script>
