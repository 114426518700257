<template>
  <div class="sidebar-widget">
    <div class="widget-title">
      <h2>Filtros</h2>
      <!--a @click="toggle">Toggle</a-->
    </div>
    <div class="widget-body" :class="bodyCss">
      <form novalidate v-on:submit.prevent>
        <fieldset>
          <label>Texto</label>
          <input type="text" v-model="query.text" />

          <label class="my-2">Precio mínimo / máximo</label>
          <div class="input-group">
              <input
                v-model="query.min"
                type="text"
                v-on:keyup.enter="filter"
              />
              <input
                v-model="query.max"
                type="text"
                v-on:keyup.enter="filter"
              />
          </div>
          <label class="my-2">Condición</label>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="query.condition" value="new" id="condition-new">
              <label class="form-check-label" for="condition-new">
                Nuevo
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="query.condition" value="used" id="condition-used">
              <label class="form-check-label" for="condition-used">
                Usado
              </label>
            </div>
        </fieldset>
        <div class="button-group">
            <button type="button" v-on:click="clear()">
              Limpiar
            </button>
            <button type="button" class="primary" v-on:click="filter">
              Buscar
            </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: this.getQuery(),
      expanded: true
    };
  },
  watch: {
    $route(to, from) {
      // la categoria es exterior a estos filtros, por eso 
      // necesito tenerlo sincronizado
      if (from.name != to.name) {
        return;
      }
      this.query = this.getQuery();
    }
  },
  props: {
    p: {
      type: Number,
      default: 1
    }
  },
  computed: {
    filters() {
      return [];
    },
    bodyCss() {
      return {
        'hidden': !this.expanded
      }
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    getQuery() {
      var q = Object.assign({}, this.$route.query);

      if (q.condition) {
        q.condition = q.condition.split(',');
      } else {
        q.condition = [];
      }

      //console.log('query', query);
      
      return q;
    },
    clear() {
      /*this.form = {
        text: "",
        min: "",
        max: ""
      };
      this.updateRoute();*/
    },
    filter() {
      var q = Object.assign({}, this.query);
      console.log('condition.pre', q);
      
      if (q.condition) {
        q.condition = q.condition.join(',');
      }

      for (var prop in q) {
        console.log(prop);
        if (q[prop] == "") {
          delete q[prop];
        }
      };

      console.log('condition.post', q);
      this.$emit('changedFilters', q);
    }
  }
};
</script>
