<template>
  <div class="register-view">
    <h1>Registro</h1>
    <register-form></register-form>
    <auth-sidebar></auth-sidebar>
  </div>
</template>

<script>
import RegisterForm from "../component/RegisterForm.vue";
import AuthSidebar from "./AuthSidebarView.vue";

export default {
  name: "RegisterModal",
  id: "register-modal",
  components: {
    RegisterForm,
    AuthSidebar
  }
}
</script>
<style>
.register-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}
.register-view h1 {
  grid-column: 1;
  grid-row: 1;
}
.register-view form {
  grid-column: 1;
  grid-row: 2;
}
</style>