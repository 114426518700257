<template>
  <div id="hamburger-submenu">
    <ul class="menu">
      <template v-for="item in menu">
        <li v-if="!item.menu" :key="item.name">
          <!--a v-if="item.method" @click="emit(item.method)">
            <i v-if="item.icon" :class="item.icon"></i>
            <span v-if="item.title">{{ item.title }}</span>
            <span v-if="item.badge" class="badge">{{item.badge}}</span>
          </a-->
          <router-link v-if="item.route" @click="close" :to="{ name: item.route }">
            <i v-if="item.icon" :class="item.icon"></i>
            <span v-if="item.title">{{ item.title }}</span>
            <span v-if="item.badge" class="badge">{{item.badge}}</span>
          </router-link>
        </li>

        <li v-if="item.menu" :key="item.name">
          <a @click="open(item)">
            <i v-if="item.icon" :class="item.icon"></i>
            <span v-if="item.title">{{ item.title }}</span>
            <span v-if="item.badge" class="badge">{{item.badge}}</span>
          </a>
        </li>
        <!--menu-dropdown @emit="emit" :key="item.name" v-if="item.menu" :item="item"></menu-dropdown-->
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  id: "hamburger-submenu",
  name: "HamburgerSubmenu",
  methods: {
    open(item) {
      console.log(item);
    },
    close() {
      this.resolve('close');
    }
  },
  props: {
    menu: {
      type: Array,
      required: true
    }
  }
};
</script>

<style>

</style>