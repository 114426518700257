<template>
  <FormControlWrapper :label="label" :errors="errors">
    <span>
       <password-visibility input="login-password"></password-visibility>
    </span>
    <input type="password" ref="control" id="login-password" :value="modelValue" :class="css" v-bind="$attrs" placeholder="Contraseña"
      @input="updateModelValue" />
  </FormControlWrapper>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';
import FormValueMixin from './FormValueMixin.vue';
import PasswordVisibility from './PasswordVisibility.vue';

var constraints = {
  lo: "(?=.*[a-z])",
  up: "(?=.*[A-Z])",
  nu: "(?=.*[0-9])",
  si: "(?=.*[!@#\$%\^&\*])",
  le: "(?=.{8,})",
};

var msgs = {
  lo: "Falta una letra minúscula",
  up: "Falta una letra mayúscula",
  nu: "Falta un número",
  si: "Falta un símbolo",
  le: "La contraseña es muy corta",
};

export default {
  mixins: [FormValueMixin],
  name: "FormPasswordWrapper",
  methods: {
    _validate(value) {
      this.validatePassword(value);
    },
    validatePassword(value) {
      //console.log('validatePassword', value);
      if (this.isLogin) {
        var reg = new RegExp(`^${constraints.le}`);
        if (!reg.test(value)) {
          this.errors.push(msgs.le);
        }
      } else {
        for (var c in constraints) {
          var exp = constraints[c];
          var reg = new RegExp(`^${exp}`);
          if (!reg.test(value)) {
            this.errors.push(msgs[c]);
          }
        }
      }

      if (this.compare != "") {
        var ref = this.findRefByName(this.compare);
        if (ref) {
          //console.log('compare', ref);
          if (ref.modelValue != value) {
            this.errors.push('Las contraseñas no coinciden');
          }
        }
      }
    }
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    compare: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  components: { FormControlWrapper, PasswordVisibility }
};
</script>