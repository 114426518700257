<template>
  <div class="listings-item">
    <img v-bind:src="cardimg(item)" v-bind:alt="'Foto de ' + item.title">
    <h3>
      <!--a href="#" @click.prevent="view(item.id)">{{item.title}}</a-->
      <router-link :to="{name:'listing-view', params: {id: item.id}}">{{item.title}}</router-link>
    </h3>

    <div class="dt-info">
      <div>
          <div class="dt">Estado</div>
          <div class="dd">{{item.condition.value}}</div>
      </div>
      <div>
          <div class="dt">Precio</div>
          <div class="dd">
            <span v-if="item.currency">
              {{ item.currency.symbol }} {{ item.price }}
            </span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListingDetail from "../views/ListingDetail.vue";

export default {
  name: "ListingItemCard",
  id: "listing-item-card",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    thumb(img) {
      return this.$config.IMG_URL + img.imageUrl
    },
    cardimg(img) {
      //if ()
      if (img.imageUrl == '') {
        return this.$config.IMG_URL + '../not-found.800x600.jpg';
      } else {
        return this.$config.IMG_URL + img.imageUrl.replace('90x90', '300x225');
      }
      
    },
    view(id) {
      this.$openModal({
        component: ListingDetail,
        props: { listingId: id },
        width: 80
      });
    }
  }
};
</script>
