<template>
  <img @error="setAltImg" />
</template>
<script>
export default {
  name: "ImageWrapper",
  methods: {
    setAltImg(event) {
      event.target.src = `https://img.liberamerkato.local/not-found.${this.size}.jpg`;
    }
  },
  props: {
    size: {
      type: String,
      required: true
    }
  }
};
</script>
