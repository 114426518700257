<template>
  <select class="" v-model="statusId" @change="updateStatus">
    <option v-for="option in options" :key="option.id" :value="option.id">{{option.name}}</option>
  </select>
</template>

<script>
import { useListingStore } from '../stores/listings';

export default {
  name: "ListingStatusChange",
  props: {
    id: {
      type: String,
      default() {
        return 'inactive';
      }
    },
    listingId: {
      type: Number,
      required: true,
    }
  },
  data() {
    var status = this.id;
    if (status === null) {
      status = 'inactive';
    }
    return {
      statusId: status,
      options: [
        {id: 'inactive', name: 'Inactivo'},
        {id: 'active', name: 'Activo'},
        {id: 'paused', name: 'Pausado'}
      ]
    }
  },
  methods: {
    updateStatus() {
      const store = useListingStore();
      store.setStatus(this.statusId, this.listingId);
    }
  }
}
</script>