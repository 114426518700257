<template>
  <div class="main-wrapper">
    <h1>Nueva publicación</h1>
    <main class="container">
      <div class="row">
        <div class="col-8">
          <div class="main-content">
            <h2 class="">Configuracion</h2>
            <form>
              <listing-add-edit-form-basic></listing-add-edit-form-basic>

              <!--div class="d-flex justify-content-between mt-4">
                <button type="button" @click="cancel" class="btn btn-secondary">Cancelar</button>
                <button type="button" @click="submit" class="btn btn-primary">Siguiente</button>
              </div-->
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ListingAddEditFormBasic from '../components/ListingAddEditFormBasic.vue'
import ListingAddEditForm from '../components/ListingAddEditForm.vue'

export default {
  components: { 
    ListingAddEditFormBasic,
    ListingAddEditForm
  },
  data() {
    return {
      item: {
        type: 0,
        publish_as: 0
      }
    }
  },
  methods: {
    cancel(evt) {
      console.log('cancel', evt);
    },
    submit() {
      //console.log("submit", this.$refs.basic.$data);
    }
  }
}
</script>