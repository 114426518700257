<template>
  <div class="tag-list-wrapper">
    <ul class="tag-list">
      <li v-for="tag in tags" :key="tag.id">
        <span>{{tag.name}}</span>
        <button type="button" @click="remove(tag.id)">Quitar</button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "TagList",
  props: {
    tags: {
      type: Array,
      default() {
        return []
      }
    },
  },
  methods: {
    remove(id) {
      this.$emit('remove', id);
    }
  }
};
</script>

<style>
.tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.tag-list li {
  list-style-type: none;
  display: inline-block;
  border: 1px solid #555;
}
.tag-list li span {
  padding: 0 1rem;
}
</style>