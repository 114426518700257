<template>
  <div class="section-wrapper">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "SectionWrapper",
};
</script>
<style>
.section-wrapper {
  margin-bottom: 1rem;
}
</style>