<template>
  <div id="question-received">
    <h1>Preguntas recibidas</h1>
    <ul class="grid-list question-list">
      <li v-for="question in questions" v-bind:key="question.id" class="item">
        <div class="question">
          {{ question.question }}
        </div>
        <!--div class="thumb">
          <router-link target="_blank" :to="{name: 'listing-view', params: { id: question.listing.id }}">
            <img :src="question.listing.image_url" />
          </router-link>
          {{question.listing}}
        </div-->
        <div class="item">
          <div class="dt">Item</div>
          <div class="">
            <a href="#" @click.prevent="view(question.listing.id)">{{ question.listing.title }}</a>
          </div>
        </div>
        <div class="author">
          <div class="dt">Autor</div>
          <div class="">{{ question.user.name }}</div>
        </div>
        <div class="actions">
          <div class="button-group" role="group" aria-label="Basic example">
            <!--button type="button" class="btn btn-secondary" v-on:click="remove(question.id)">
              <i class="bi bi-trash"></i>
            </button-->
            <button type="button" class="primary" v-on:click="reply(question)">
              <i class="bi bi-reply"></i> <span>Responder</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div v-if="questions.length === 0">
      No tienes preguntas para responder.
    </div>
  </div>
</template>

<script>
import QuestionReply from "../components/modals/QuestionReply.vue";
import ListingDetail from "./ListingDetail.vue";

export default {
  name: "QuestionsReceived",
  computed: {
    questions() {
      var q = this.$store.state.questions.received;
      console.log('q', q);
      return q;
    },
  },
  methods: {
    remove(id) {
      this.$store
        .dispatch("questions/remove", { question_id: id })
    },
    reply(q) {
      var self = this;
      this.$openModal({
        component: QuestionReply,
        //cssClass: ["open-on-left"],
        props: {
          question: q,
        },
      }).then(function () {
        self.load();
      }).catch(function() {

      });
    },
    view(id) {
      var self = this;
      this.$openModal({
        component: ListingDetail,
        props: {
          listingId: id,
        },
      }).then(function () {
        self.load();
      }).catch(function() {

      });
    },
    load() {
      var self = this;
      this.$store
        .dispatch("questions/received")
        .then(function (q) {
          console.log(q);
        })
        .catch(function () {});
    },
  },
  mounted() {
    this.load();
  },
};
</script>

