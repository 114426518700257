<template>
  <div class="form-actions">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FormActionWrapper",
}
</script>