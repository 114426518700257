<template>
  <div class="form-control">
    <label v-if="label">{{ label }}</label>
    <slot></slot>
    <ul class="form-errors" v-for="err in errors" :key="err">
      <li>{{ err }}</li>
    </ul>
    <ul class="form-errors" v-for="err in apiErrors" :key="err">
      <li>{{ err }}</li>
    </ul>
  </div>
</template>

<script lang="js">
export default {
  name: "FormControlWrapper",
  mounted() {
    //console.log('FormControlWrapper', this);
  },
  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
    errors: {
      type: Array,
      required: true,
    },
    apiErrors: {
      type: Array,
      default: [],
    }
  }
}
</script>