<template>
  <div class="verify-code-modal">
    <h2>Codigo de verificación</h2>
    <p>Ingrese el código enviado por correo electrónico.</p>
    <FormWrapper autocomplete="off" :error="error">
      <FormRowWrapper>
        <FormInputWrapper ref="code" minlength="6" name="code" label="Código" v-model="code" placeholder="Codigo enviado x correo">
        </FormInputWrapper>
      </FormRowWrapper>
      <FormActionsWrapper>
        <button
            type="button"
            v-on:click="cancel()"
          >
            Cancelar
          </button>
          <button type="button" v-on:click="submit()" class="primary">Guardar</button>
      </FormActionsWrapper>
    </FormWrapper>
  </div>
</template>

<script>
//import store from "@/store";
//import PasswordVisibility from "./component/PasswordVisibility.vue";
import { FormWrapperMixin } from "@linkedcode/form-wrapper";
import { useAuthStore } from "../stores/auth";

export default {
  name: "VerifyCodeModal",
  id: "verify-code-modal",
  mixins: [FormWrapperMixin],
  data() {
    return {
      code: "",
    };
  },
  props: {
    email: {
      type: String,
    },
  },
  methods: {
    cancel() {
      this.reject("Cancelled on code verify");
    },
    submit() {
      if (this.validate()) {
        this._submit();
      }
    },
    _submit() {
      var self = this;
      const store = useAuthStore();
      store.$http.post("auth/reset/verify", {
          email: this.email,
          code: this.code
        })
        .then(function () {
          var obj = {
            props: {
              email: self.email,
              code: self.code,
            }
          };
          self.$auth.newPassModal(obj)
            .then(function (res) {
              console.log('verify reset', res);
              self.resolve(res);
            })
            .catch(function(err) {
              self.reject(err);
            });
        })
        .catch(function (error) {
          self.error = error;
        });
    },
  },
};
</script>
