<template>
  <section id="listing-view-questions">
    <div class="section-title">
      <h2>Preguntas</h2>
      <div v-if="showButton()">
        <button class="primary" v-on:click="askQuestion(item.id)">Preguntar</button>
      </div>
    </div>

    <ul class="grid-list question-item">
      <li v-for="question in questions" v-bind:key="question.id">
        <div class="user">
          {{question.name}}
        </div>
        <div class="question">
          <em>{{question.question}}</em> 
        </div>
        <div class="reply">
          <div v-if="question.response">{{question.response}}</div>
          <div v-else>---</div>
        </div>
        <div class="asked">
          <div class="dt">Preguntado</div> 
          {{question.question_at}}
        </div>
        <div class="replied">
          <div class="dt">Respondido</div> 
          <div v-if="question.response" class="">{{question.response_at}}</div>
          <div v-else>---</div>
        </div>
      </li>
    </ul>
    <div v-if="questions.length === 0">No hay preguntas.</div>
  </section>
</template>

<script>
import { useAuthStore } from "@linkedcode/auth";
import QuestionAsk from "../Questions/Ask.vue"

export default {
  name: 'ListingQuestions',
  data() {
    return {
      questions: []
    }
  },
  computed: {
    isAuthed() {
      return this.$store.getters['auth/isAuthed'];
    },
    userId() {
      const store = useAuthStore();
      return store.userId;
      //return this.$store.getters['auth/userId'];
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    showButton() {
      if (this.userId != this.item.user_id) {
        return true;
      }
      return false;
    },
    askQuestion(id) {
      var self = this;
      var p = this.$openModal({
        component: QuestionAsk,
        props: {
          listingId: this.item.id
        }
      });

      p.then(function (res) {
        //console.log(res);
        self.loadQuestions();
      }).catch(function (err) {
        console.log(err);
      });
    },
    loadQuestions() {
      var self = this;
      this.$store.dispatch("questions/load", { listing_id: this.item.id }).then(function(questions){
        self.questions = questions;
      });
    }
  },
  mounted() {
    this.loadQuestions();
  }
}
</script>