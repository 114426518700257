<template>
  <FormControlWrapper :label="label" :errors="errors" :apiErrors="apiErrors">
    <input
      type="email" ref="control"
      :value="modelValue"
      :class="css"
      v-bind="$attrs"
      placeholder="Correo electrónico"
      @input="updateModelValue"
    />
  </FormControlWrapper>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';
import FormValueMixin from './FormValueMixin.vue';

const validateEmail = (email) => {
  var regex = /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
  var s = String(email).toLowerCase().match(regex);
  return s;
};

export default {
  name: "FormEmailWrapper",
  mixins: [FormValueMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  components: { FormControlWrapper },
  mounted() {
    console.log('FormEmailWrapper', this);
  },
  methods: {
    _validate(value) {
      this.validateEmail(value);
    },
    validateEmail(email) {
      if (!validateEmail(email)) {
        this.errors.push("El correo electronico no parece valido");
      }
    },
  }
};
</script>