import FormRowWrapper from "./FormRowWrapper.vue";
import FormFieldsetWrapper from "./FormFieldsetWrapper.vue";
import FormWrapper from "./FormWrapper.vue";
import FormInputWrapper from "./FormInputWrapper.vue";
import FormEmailWrapper from "./FormEmailWrapper.vue";
import FormTextareaWrapper from "./FormTextareaWrapper.vue";
import FormSelectWrapper from "./FormSelectWrapper.vue";
import FormPasswordWrapper from "./FormPasswordWrapper.vue";
import FormActionsWrapper from "./FormActionsWrapper.vue";

export default {
  installed: false,
  install(app) {
    if (this.installed) return;
    app.component("FormRowWrapper", FormRowWrapper);
    app.component("FormFieldsetWrapper", FormFieldsetWrapper);
    app.component("FormWrapper", FormWrapper);
    app.component("FormInputWrapper", FormInputWrapper);
    app.component("FormTextareaWrapper", FormTextareaWrapper);
    app.component("FormEmailWrapper", FormEmailWrapper);
    app.component("FormSelectWrapper", FormSelectWrapper);
    app.component("FormPasswordWrapper", FormPasswordWrapper);
    app.component("FormActionsWrapper", FormActionsWrapper);
    this.installed = true;
  }
};

import FormWrapperMixin from "./FormWrapperMixin.vue";

export { FormWrapperMixin };