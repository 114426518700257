<template>
  <form>
    <div class="error" v-if="this.showError">
      <h3>{{ error.title }}</h3>
      <p v-if="error.detail">{{ error.detail }}</p>
    </div>
    <slot></slot>
  </form>
</template>

<script lang="js">
export default {
  name: "FormWrapper",
  props: {
    error: {
      type: Object,
      default() {
        return {
          status: 200
        };
      }
    },
  },
  mounted() {
    //console.log(this.$slots);
  },
  computed: {
    showError() {
      //console.log(this.error);
      return this.error.status !== 200;
    },
    errors() {
      if (typeof this.error['invalid-params'] !== 'undefined') {
        return this.error['invalid-params'];
      } else {
        return [];
      }
    }
  }
};
</script>
