import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const useSystemAreaStore = defineStore('systemArea', {
  state: () => ({
    applets: []
  }),
  actions: {
    add(applet) {
      this.applets.push(markRaw(applet));
    }
  }
});
