<template>
  <div class="form-container">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Informacion General</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="images-tab" data-bs-toggle="tab" data-bs-target="#images" type="button" role="tab" aria-controls="images" aria-selected="false">Imagenes</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="category-tab" data-bs-toggle="tab" data-bs-target="#category" type="button" role="tab" aria-controls="category" aria-selected="false">Categoria</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="address-tab" data-bs-toggle="tab" data-bs-target="#address" type="button" role="tab" aria-controls="address" aria-selected="false">Direccion</button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <listing-add-edit-form-basic></listing-add-edit-form-basic>
      </div>
      <div class="tab-pane fade py-2" id="images" role="tabpanel" aria-labelledby="profile-tab">
        <div class="listing-edit-images row">
          <div v-for="image in images" v-bind:key="image.id" class="col-2 listing-edit-image">
            <div class="vstack gap-2">
            <img class="img-fluid border border-dark" v-bind:src="image.path" />
            <button class="btn btn-secondary" v-on:click="remove(image.id)">Remover</button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="category" role="tabpanel" aria-labelledby="category-tab">
        <listing-category class="mb-4" @cambio="setCategory($event)" v-if="item" v-bind:item="item">
        </listing-category>
      </div>
      <div class="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">
        <form v-if="item">
          <address-select v-bind:selected="item.address_id"></address-select>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <button type="button" @click="cancel" class="btn btn-secondary">Cancelar</button>
      <button type="button" v-on:click="save()" class="btn btn-primary">Guardar</button>
    </div>
  </div>
</template>

<script>
import ListingAddEditFormBasic from './ListingAddEditFormBasic.vue'
import ListingCategorySelect from './ListingCategorySelect.vue'

export default {
  components: { ListingCategorySelect, ListingAddEditFormBasic },
  name: "ListingAddEditForm",
  props: {
    listing: {
      type: Object,
      required: true
    }
  }
}
</script>