<template>
  <div class="page-title">
    <h1>{{ title }}</h1>
    <div class="page-title-actions" v-if="this.$slots.actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>