import LayerModal from "./components/LayerModal.vue";
import { useModalStore } from "./stores/modal";

export default {
  installed: false,
  install(app, options) {
    if (this.installed) return;

    app.component("layer-modal", LayerModal);


    /*if (typeof app.config.globalProperties.$emitter === 'undefined') {
        console.warn('Se necesita app.config.globalProperties.$emitter');
    }

    options.router.beforeEach((to, from) => {
      //console.log("layerModal", to, from);
      if (to.fullPath !== from.fullPath) {
        app.config.globalProperties.$emitter.emit('CLOSE_ALL_LAYER_MODAL');
      }
      return true;
    })*/

    const store = useModalStore(options.pinia);

    app.config.globalProperties.$openModal = function(opts) {
      let a = app.component(opts.component.id);

      if (typeof a === 'undefined') {
        //delete app._context.components[opts.component.id];
        console.log("Registrando componente", opts.component.name);
        app.component(opts.component.id, opts.component);
      }

      return store.openModal(opts);
    }


    this.installed = true;
  }
};
