<template>
  <form v-if="item">
    <FormFieldsetWrapper legend="Configuracion">
      <FormRowWrapper>
        <FormSelectWrapper ref="type" v-model="item.type" :options="options.type" required="true">
        </FormSelectWrapper>
      </FormRowWrapper>
    </FormFieldsetWrapper>
      <!--label>Publicar como</label>
      <select v-model="item.publish_as" class="form-select">
        <option value="0">- Seleccione -</option>
        <option value="1">Particular</option>
      </select-->

    <FormFieldsetWrapper legend="Informacion de la publicación">
      <FormRowWrapper>
        <FormInputWrapper ref="title" label="Titulo" v-model="item.title" minlength="10">
        </FormInputWrapper>
      </FormRowWrapper>
      <FormRowWrapper>
        <FormTextareaWrapper ref="description" label="Descripción" minlength="10" v-model="item.description">
        </FormTextareaWrapper>
      </FormRowWrapper>
      <FormRowWrapper>
        <FormInputWrapper ref="price" type="number" min="0" label="Precio" v-model="item.price">
        </FormInputWrapper>
        <FormSelectWrapper ref="currency" label="Moneda" v-model="item.currencyId" :options="currencies" required="true">
        </FormSelectWrapper>
      </FormRowWrapper>
      <FormRowWrapper>
        <FormSelectWrapper ref="operation" label="Operacion" v-model="item.operation" :options="options.operation" required="true" />
      </FormRowWrapper>
      <FormRowWrapper v-if="showCondition">
        <FormSelectWrapper ref="condition" label="Condicion" v-model="item.condition" :options="options.condition" required="true" />
      </FormRowWrapper>
      <FormRowWrapper v-if="showStock">
        <FormInputWrapper ref="quantity" label="Cantidad disponible" type="number" min="0" v-model="item.availableQuantity" />
      </FormRowWrapper>
      <FormRowWrapper v-if="listing.id">
        <FormSelectWrapper ref="status" label="Estado de la publicación" v-model="item.status" :options="options.status" />
      </FormRowWrapper>
    </FormFieldsetWrapper>

    <FormFieldsetWrapper legend="Categoría">
      <listing-category-select ref="categoryId" v-model="item.categoryId" v-if="listing" v-bind:listing="listing">
      </listing-category-select>
    </FormFieldsetWrapper>

    <FormFieldsetWrapper legend="Dirección">
      <FormRowWrapper>
        <address-select ref="address" v-if="item" v-model="item.addressId"></address-select>
      </FormRowWrapper>
    </FormFieldsetWrapper>

    <FormActionsWrapper>
      <button type="button" @click="cancel" class="">Cancelar</button>
      <button type="button" @click="submit" class="primary">Guardar</button>
    </FormActionsWrapper>
  </form>

</template>

<script>

import { FormWrapperMixin } from "@linkedcode/form-wrapper";
import { useListingStore } from "../stores/listings";

var listingDTO = {
  id: 0,
  title: "",
  description: "",
  price: 1,
  currencyId: 10,
  addressId: "",
  availableQuantity: 0,
  listingTypeId: 0,
  conditionId: 0,
  operationId: 0,
  categoryId: 0
}

import ListingCategorySelect from './ListingCategorySelect.vue';
export default {
  mixins: [
    FormWrapperMixin,
  ],
  components: {
    ListingCategorySelect
  },
  name: "ListingAddEditFormBasic",
  props: {
    listing: {
      type: Object,
      validator(value) {
        console.log('validator', value);
        return true;
      },
      default(rawProps) {
        // this no existe
        return {};
      }
    }
  },
  data() {
    //console.log('listing', this.listing);
    var _item = {
      title: this.listing.title || "Titulo de 10 caracteres",
      description: this.listing.description || "Titulo de 10 caracteres",
      price: this.listing.price || 1,
      currencyId: this.listing.currency?.id || 10,
      addressId: this.listing.address?.id || 1,
      availableQuantity: this.listing.availableQuantity || 1,
      type: this.listing.type?.id || "product",
      condition: this.listing.condition?.id || "new",
      operation: this.listing.operation?.id || "sell",
      categoryId: this.listing.category?.id || 4,
      status: this.listing.status || "inactive"
    }
    //console.log('listingdto', _item);
    return {
      item: _item,
      currencies: [
        { value: 0, name: "- Seleccione -" },
        { value: 10, name: "ARS"},
        { value: 5, name: "USD" },
      ],
      options: {
        type: [
          { value: 0, name: "- Seleccione -" },
          { value: "product", name: "Producto" },
          { value: "service", name: "Servicio" },
        ],
        condition: [
          { value: 0, name: "- Seleccione -" },
          { value: "new", name: "Nuevo"} ,
          { value: "used", name: "Usado"}
        ],
        status: [
          { value: "inactive", name: "No Activa" },
          { value: "active", name: "Activa" },
          { value: "paused", name: "Pausada" }
        ],
        operation: [
          { value: 0, name: "- Seleccione -" },
          { value: "buy", name: "Compra" },
          { value: "sell", name: "Venta" },
          { value: "auction", name: "Subasta" },
        ]
      }
    }
  },
  methods: {
    submit() {
      console.log('submit', this.item);
      if (!this.validate()) {
        return;
      }
      var self = this;
      const store = useListingStore();
      if (this.listing.id) {
        store.update(this.listing.id, this.item).then(function(listingId) {
          //console.log(listingId);
          self.$router.push({name:'listings-own'});
        }).then(function() {
          self.$emit('save', 'ok');
          //self.resolve("OK");
        });
      } else {
        store.create(this.item).then(function(data) {
          if (self.$route.name != 'listings-own') {
            self.$router.push({name:'listings-own'});
          } else {
            //self.resolve(data);
            self.$emit('save', 'ok');
          }
        });
      }
    },
    isProduct() {
      if (this.item.type == "product") {
        return true;
      } else {
        return false;
      }
    },
    /*setCategory(categoryId) {
      this.item.category_id = categoryId;
      this.saveCategory(categoryId);
    },*/
    saveCategory(categoryId) {
      var fields = {
        listing_id: this.listing.id,
        category_id: categoryId
      };
      this.$store.dispatch('listings/category', fields)
    },
    updateAddress(addressId) {
      this.item.address_id = addressId;
      if (this.listing.id) {
        this.saveAddress(addressId);
      }
    },
    saveAddress(addressId) {
      var fields = {
        listing_id: this.listing.id,
        address_id: addressId
      };
      this.$store.dispatch('listings/address', fields)
    },
    diff() {
      return Object.keys(this.item).reduce((diff, key) => {
        if (this.item[key] === this.listing[key]) return diff
        return {
          ...diff,
          [key]: this.item[key]
        }
      }, {})
    },
    cancel() {
      this.$emit('reject', 'close');
    }
  },
  computed: {
    showStock() {
      return this.isProduct();
    },
    showCondition() {
      return this.isProduct();
    }
  }
}
</script>