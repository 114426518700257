<template>
  <div class="listing-buy">
    <div class="row py-3">
      <!--div class="col-4 d-flex justify-content-center align-items-center">
        <div class="d-flex gap-2 listing-buy-price">
          <span class="price">
            {{item.price}}
          </span>
          <span class="currency">
            {{item.currency.code}}
          </span>
        </div>
      </div-->
      <div class="col-4">
        <div class="input-group">
          <button class="btn btn-secondary" type="button" @click="dec">-</button>
          <input type="text" readonly class="form-control text-center" v-model="quantity" />
          <button type="button" class="btn btn-secondary" @click="inc">+</button>
        </div>
      </div>
      <div class="col-4">
        <div class="d-grid">
          <button type="button" @click="buy" class="btn btn-primary">Comprar</button>
        </div>
      </div>
      <!--div class="col-4">
        <div class="align-middle">
          Disponible: {{item.available_quantity}}
        </div>
      </div-->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      quantity: 1
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    inc() {
      if (this.quantity < this.item.available_quantity) {
        this.quantity++;
      }
    },
    dec() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    buy() {
      this.$store.dispatch('listings/buy', { 
        listing_id: this.item.id,
        payload: {
          quantity: this.quantity
        }
      });
    }
  },
  mounted() {
    //console.log(this.item);
  }
}
</script>
