export default {
  name: "AppsMenu",
  data() {
    return {
      available: false
    }
  },
  computed: {
    applications: function() {
      return this.$store.state.apps.applications;
    },
    changeable: function() {
      return (this.$store.state.apps.applications.length > 1);
    },
    active: function() {
      return this.$store.state.apps.active;
    }
  },
  methods: {
    activate(name) {
      this.$store.dispatch("apps/activate", name);
      this.change();
    },
    change() {
      this.available = !this.available;
    }
  }
};
