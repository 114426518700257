<template>
  <div class="listing-edit-images">
    <div class="listing-edit-images-item" v-for="image in images" v-bind:key="image.id">
      <img v-bind:src="img(image)" />
      <div class="btn-group">
        <button v-if="image.isDefault === 0" class="btn btn-outline-primary" v-on:click="setThumb(image.id)">Miniatura</button>
        <button class="btn btn-outline-primary" v-on:click="remove(image.id)">Remover</button>
      </div>
    </div>
    <div v-if="images.length == 0" class="row">
      <div class="col-12">
        Parece que aun no hay imagenes
      </div>
    </div>
    <div class="d-flex mt-2 justify-content-end pt-2">
      <button type="button" class="primary" @click="upload">Subir imagen</button>
    </div>
  </div>
</template>
<script>
import ListingCrop from '../components/ListingCrop.vue';
import { useListingStore } from '../stores/listings';

export default {
  data() {
    return {
      images: []
    }
  },
  props: {
    listing: {
      type: Object
    }
  },
  components: {
    ListingCrop
  },
  mounted() {
    this.loadImages();
  },
  methods: {
    img(img) {
      return this.$config.IMG_URL + img.path;
    },
    setImages(images) {
      this.images = images;
    },
    upload(id) {
      var self = this;
      this.$openModal({
        component: ListingCrop,
        props: { listingId: this.listing.id },
        cssClass: ["open-on-right"]
      }).then(function() {
        self.loadImages();
      }).catch(function(err) {
        console.log(err);
        self.loadImages();
      });
    },
    setThumb(imageId) {
      const self = this;
      const store = useListingStore();
      store.setDefault(this.listing.id, imageId).then(function(item) {
        self.loadImages();
      });
    },
    remove(imageId) {
      const self = this;
      const store = useListingStore();
      store.removeImage(this.listing.id, imageId).then(function(item) {
        self.loadImages();
      });
    },
    loadImages() {
      const self = this;
      const store = useListingStore();
      store.getImages(this.listing.id).then(function(item) {
        self.setImages(item);
      });
    }
  }
}
</script>
<style>
.listing-edit-images-item {
  display: flex;
  gap: 1rem;
}
.listing-edit-images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.listing-edit-images-item img {
  width: 200px;
}
</style>