import { defineStore } from 'pinia'

export const useAppsStore = defineStore('apps', {
  state: () => ({
    active: false,
    applications: []
  }),
  actions: {
	change(name) {
      let newApp = this.getApp(state, name);
      if (newApp) {
        this.active = newApp;
      }
    },
    activate(name) {
      if (this.active !== null && this.active.name === name) {
        console.log("moduleApps: misma app, sale");
        return;
      }

      //let changeRoute = true;
      let newApp = this.getApp(name);

      if (newApp !== false) {
        /*if (!router.currentRoute.meta) {
        }*/

        //console.log("router.currentRoute::", router.currentRoute);
        /*if (router.currentRoute.meta && router.currentRoute.meta.app === name) {
          changeRoute = false;
        }*/

        this.active = newApp;

        /*if (changeRoute) {
          //console.log("Navegando hacia", newApp.route);
          router.push({ name: newApp.route });
        }*/
      }
    },
    getApp(name) {
	  let newApp = false;

	  this.applications.forEach(function(app) {
		if (app.name === name) {
		  if (this.active === null || this.active.name !== name) {
			newApp = app;
		  }
		}
	  }, this);

	  return newApp;
	},
    addApplication(app) {
      //console.log("Agregando app", app.name);
      app.routes.forEach(function(route) {
        if (typeof route.meta !== "undefined") {
          route.meta.app = app.name;
        } else {
          route.meta = {
            app: app.name
          };
        }
      });

      this.applications.push(app);
    }
  }
});
