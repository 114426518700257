<template>
  <div class="sidebar-widget">
    <div class="widget-title">
      <h2>Categorias</h2>
    </div>
    <div class="widget-body">
      <ul v-if="available.length > 0">
        <li v-for="cat in available" v-bind:key="cat.id">
          <a href="#" @click.prevent="updateCategory(cat.id)">
            {{cat.name}}
          </a>
        </li>
      </ul>
      <div v-if="available.length == 0">
        No hay subcategorias
      </div>
    </div>
  </div>
</template>

<script>
import { useListingStore } from '../stores/listings';

export default {
  name: "ListingCategories",
  id: "listing-categories",
  props: {
    categoryId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      categories: []
    }
  },
  computed: {
    available() {
      const store = useListingStore();
      return store.filters.categories;
    },
    selected() {
      const store = useListingStore();
      return store.filters.category;
    }
  },
  methods: {
    updateCategory(id) {
      this.$emit('categoryChanged', id);
      const store = useListingStore();
      store.filterByCategory(id);
    }
  },
  watch: {
    $route(to, from) {
      console.log('lc.watch.route:to:from', to, from);
      if (to.query.cat != from.query.cat) {
        const store = useListingStore();
        store.filterByCategory(to.query.cat);
      }
    }
  },
  mounted() {
    if (this.$route.query.cat) {
      var cat = this.$route.query.cat;
      this.updateCategory(cat);
    } else {
      const store = useListingStore();
      store.categories({});
    }
  }
};
</script>
