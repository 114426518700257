<template>
  <div class="listings-add">
    <div class="listings-add-title">
      <h1>Nueva publicacion</h1>
      <div class="btn-group ms-auto">
        <button type="button" class="btn btn-outline-secondary" @click="close">
          <i class="bi bi-x">X</i>
        </button>
      </div>
    </div>
    <div class="listing-add-body">
      <listing-add-edit-form-basic @save="save" :item="item">
      </listing-add-edit-form-basic>
      <div class="aside">
      </div>
    </div>
  </div>
</template>

<script>
import ListingAddEditFormBasic from '../components/ListingAddEditFormBasic.vue'

export default {
  name: "ListingNew",
  id: "listing-new",
  components: {
    ListingAddEditFormBasic,
  },
  data() {
    return {
      item: {}
    }
  },
  methods: {
    save(data) {
      this.resolve(data);
    },
    close() {
      this.reject("Closed");
    },
    updateBasic() {
      console.log(this);
    },
    updateCategory(evt) {
      console.log('updateCat', evt);
    },
    submit() {
      console.log("submit", this.$refs.basic.$data);
    }
  }
}
</script>
<style>
.listings-add-title {
  display: flex;
  justify-content: space-between;
}
.listing-add-body {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
</style>