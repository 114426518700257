<template>
  <div class="form-row-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FormRowWrapper",
};
</script>
<style>
.form-row-wrapper {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 1rem;
}
</style>