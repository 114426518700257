<template>
  <FormRowWrapper>
    <FormSelectWrapper label="Seleccione una categoría" @change="update(0)" ref="level1" v-model.number="values[0]" :options="formatOptions(nivel[0])" required="true" />
  </FormRowWrapper>

  <FormRowWrapper v-if="nivel[1].length">
    <FormSelectWrapper @change="update(1)" ref="level2" v-model.number="values[1]" :options="formatOptions(nivel[1])" required="true" />
  </FormRowWrapper>

  <FormRowWrapper v-if="nivel[2].length">
    <FormSelectWrapper @change="update(2)" ref="level3" v-model.number="values[2]" :options="formatOptions(nivel[2])" required="true" />
  </FormRowWrapper>

  
  <!--FormRowWrapper label="Seleccione una categoría">
    <select v-on:change="update(0)" v-model="values[0]">
      <option ref="nivel0" v-for="item2 in nivel[0]" v-bind:key="item2.id" :value="item2.id">
        {{ item2.name }}
      </option>
    </select>
  </FormRowWrapper-->
  <div v-if="showSaveButton">
    <button type="button" @click="submit" class="primary">Actualizar categoria</button>
  </div>
</template>

<script>

import { useListingStore } from '../stores/listings';

export default {
  name: "ListingCategorySelect",
  id: "listing-category",
  props: {
    listing: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Number
    },
    showSaveButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: 0,
      eventName: 'changed',
      breadcrumb: false,
      select: {
        'id': 0,
        'name': ' - Seleccione - '
      },
      nivel: [[],[],[],[],[],[],[]],
      values: [0, 0, 0, 0, 0, 0, 0]
    }
  },
  emits: ["update:modelValue"],
  methods: {
    validate() {
      var valid = true;
      for (var ref in this.$refs) {
        console.log('ListingCategorySelect', 'validate', ref);
        if (!this.$refs[ref].validate()) {
          valid = false;
        }
      }
      return valid;
    },
    formatOptions(options) {
      var newOptions = [];
      options.forEach(function(opt) {
        newOptions.push({value:opt.id, name:opt.name});
      });
      return newOptions;
    },
    update(event) {
      //console.log(event);
      let val = this.values[event];
      this.value = val;
      this.clear(event + 1);
      if (val > 0) {
        this.load(event + 1, val);
        this.$emit("update:modelValue", val);
      } else {
        if (event > 0) {
          let val = this.values[event - 1];
          this.$emit("update:modelValue", val);
        }
      }
    },
    submit() {
      this.$store.dispatch('listings/category', {
        listing_id: this.listing.id,
        category_id: this.value
      });
    },
    clear(n) {
      for (var i = n; i < 7; i++) {
        this.nivel[i] = [];
      }
    },
    load(n, value) {
      var self = this;
      const store = useListingStore();
      store.$http.get(`/categories?parent_id=${value}`).then(function(response){
        var options = response.data;
        if (options.length > 0) {
          options.unshift(self.select);
          self.nivel[n] = options;
        }
      });
    }
  },
  mounted() {
    var self = this;
    const store = useListingStore();
    store.$http.get('/categories').then(function(response){
      var options = response.data;
      if (options.length > 0) {
        options.unshift(self.select);
        self.nivel[0] = options;
      }
    });
    if (this.listing.category && this.listing.category.id) {
      store.$http.get(`/categories/${this.listing.category.id}`).then(function(response){
        self.breadcrumb = response.data.breadcrumb;
        //console.log(self.breadcrumb);
        self.breadcrumb.forEach(function(val, idx) {
          //console.log("breadcrumb", idx, val);
          self.values[idx] = val.id;
          self.update(idx);
        })
      });

    }
  }
};
</script>
