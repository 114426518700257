<template>
  <div class="listings-home">
    <h1>Ultimas publicaciones</h1>
    <div class="listings-list">
      <listing-item-card v-for="listing in items" :key="listing.id" v-bind:item="listing"></listing-item-card>
    </div>
  </div>
</template>

<script>
import ListingItem from "../components/ListingItem.vue"
import ListingItemCard from "../components/ListingItemCard.vue"
import { useListingStore } from "../stores/listings";

export default {
  mounted() {
    this.store.loadNewest();
  },
  computed: {
    store() {
      return useListingStore(this.$pinia);
    },
    items() {
      return this.store.newest;
    },
  },
  components: {
    ListingItem,
    ListingItemCard
  },
};
</script>

