import PageTitle from "./components/PageTitle.vue"
import PageContent from "./components/PageContent.vue"
import ModalTitle from "./components/ModalTitle.vue"

import SectionWrapper from "./components/SectionWrapper.vue"
import SectionTitle from "./components/SectionTitle.vue"

import TagList from "./components/TagList.vue"

import ImageWrapper from "./components/ImageWrapper.vue"

export default {
  installed: false,
  install(app) {
    if (this.installed) return;
    
    app.component('page-title', PageTitle);
    app.component('page-content', PageContent);
    app.component('modal-title', ModalTitle);
    app.component('section-wrapper', SectionWrapper);
    app.component('section-title', SectionTitle);
    app.component('ImageWrapper', ImageWrapper);
    
    app.component('tag-list', TagList);

    this.installed = true;
  }
}

//export { ImageWrapper };
