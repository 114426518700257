<script>
export default {
  data() {
    return {
      error: {
        status: 200,
      }
    }
  },
  watch: {
    error(newError, oldError) {
      //console.log('newError', newError, this.$refs);
      if (newError['invalid-params']) {
        newError['invalid-params'].forEach(function(param, idx) {
          //console.log(param);
          if (this.$refs[param.name]) {
            this.$refs[param.name].updateApiErrors(param);
          }
        }, this);
      }
    }
  },
  methods: {
    resetErrors() {
      this.error = {
        status: 200,
      };
    },
    validate() {
      if (Object.keys(this.$refs).length === 0) {
        console.error("No hay 'refs' en el formulario");
        return false;
      }
      //console.log('validate this', this);
      //console.log('validate refs', this.$refs);
      var valid = true;
      for (var ref in this.$refs) {
        if (this.$refs[ref].validate() === false) {
          valid = false;
        }
      }

      if (!valid) {
        this.$notifications.error("Hay errores en el formulario");
      }

      return valid;
    },
    /*getApiErrors(field) {
      //console.log(field, this.error);
      var errors = [];
      if (this.error) {
        if (this.error['invalid-params']) {
          var params = this.error['invalid-params'];
          params.forEach(function(err) {
            errors.push(err.reason);
          })
        }
      }
      //console.log('apierrors', errors);
      return errors;
    },*/
    preSubmit() {
      //console.log("preSubmit");
      return this.validate();
    },
  },
};
</script>
