<template>
  <FormControlWrapper :label="label" :errors="errors">
    <input :type="type" ref="control" :value="modelValue" :class="css" v-bind="$attrs"
      @input="updateModelValue" />
  </FormControlWrapper>
</template>

<script lang="js">
import FormControlWrapper from './FormControlWrapper.vue';
import FormValueMixin from './FormValueMixin.vue';

export default {
  mixins: [FormValueMixin],
  name: "FormInputWrapper",
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
  },
  components: { FormControlWrapper }
};
</script>