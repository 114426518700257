<template>
  <h3>Subasta</h3>
  <div class="listing-bid">

    <!--div class="quantity-form">
      <button type="button" v-on:click="dec()">-</button>
      <input type="text" v-model="quantity" placeholder="Cantidad" />
      <button type="button" v-on:click="inc()">+</button>
    </div-->
    <div class="listing-bid-price">
      <button type="button" @click="dec()">-</button>
      <input class="bid-price" type="text" v-model="price" placeholder="Precio unitario" />
      <button type="button" @click="inc()">+</button>
    </div>
    <button :disabled="!validBid" type="button" @click="bidding()" class="primary">Ofertar</button>
  </div>
  <div>
    <dl>
      <dt>Ofertantes</dt>
      <dd>{{ this.info.users }}</dd>
      <dt>Ofertas</dt>
      <dd>{{ this.info.count }}</dd>
    </dl>
  </div>
</template>

<script lang="js">
import { useListingStore } from '../stores/listings';

export default {
  data() {
    return {
      info: {},
      quantity: 1,
      price: this.item.price,
      minPrice: this.item.price
    }
  },
  computed: {
    validBid() {
      return this.price > this.item.price ? true : false;
    },
    step() {
      return 1;
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    inc() {
      this.price += this.step;
    },
    dec() {
      if (this.price <= this.minPrice) {
        return;
      }
      this.price -= this.step;
    },
    bidding() {
      const self = this;
      const store = useListingStore();
      store.bid(this.item.id, this.price, this.quantity)
      .then(function() {
        self.$notifications.success("Oferta realizada");
        self.loadInfo();
      })
      .catch(function(err) {
        self.$notifications.error(err.response.data.error);
      });
    },
    loadInfo() {
      const self = this;
      const store = useListingStore();
      store.bidInfo(this.item.id).then(function (data) {
        //console.log(data);
        self.price = data.max + 1;
        self.minPrice = data.max + 1;
        self.info = data;
      });
    }
  },
  mounted() {
    this.loadInfo();
  }

}
</script>

<style>
.listing-bid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;
}
.listing-bid-price {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  gap: 1rem;
}
.bid-price {
  text-align: center;
}
</style>
