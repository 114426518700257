<template>
  <ol aria-label="breadcrumb" class="breadcrumb">
    <li>
      <router-link :to="{ name: 'listings', query: q }"><!--i class="bi bi-house"></i-->Inicio</router-link>
    </li>
    <li v-for="item in tree" v-bind:key="item.id">
      <router-link :to="cat(item.id)">{{ item.name }}</router-link>
    </li>
  </ol>
</template>

<script>
import { useListingStore } from '../stores/listings';

export default {
  name: "LimeBreadcrumb",
  computed: {
    q() {
      var q = Object.assign({}, this.$route.query);
      delete q.cat;
      return q;
    }
  },
  props: {
    tree: {
      type: Array
    }
  },
  methods: {
    cat(id) {
      //var q = Object.assign({}, this.$route.query, { cat: item.id });
      var q = Object.assign({}, this.$route.query, { cat: id });
      return { name: 'listings', query: q };
    },
    home() {
      //
      console.log("home");
      var q = this.query;
      this.$router.push({name:'listings', query: q, replace: true});
      console.log(q);
    }
  }
};
</script>
