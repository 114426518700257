<template>
  <div id="settings">
    <h2>Configuración</h2>
    <ul class="list-group">
      <li>
          <i class="bi bi-person big-icon"></i>
          <div>
            <a href="#" @click.prevent="profile">Perfil</a>
            <div>Nombre de usuario, redes sociales</div>
          </div>
      </li>
      <li v-for="setting in settings" :key="setting.name">
        <i :class="setting.icon"></i>
        <div class="vstack">
          <a href="#" @click="openModal(setting.component)">{{setting.name}}</a>
          <div><small>{{setting.description}}</small></div>
        </div>
      </li>
      
      <!--li class="list-group-item">
        <div class="hstack gap-3">
          <i class="bi bi-lock big-icon"></i>
          <div class="vstack">
            <a href="#">Seguridad</a>
            <div><small>Contraseña</small></div>
          </div>
        </div>
      </li-->
      <!--li class="list-group-item">
        <div class="hstack gap-3">
          <i class="bi bi-house big-icon"></i>
          <div class="vstack">
            <a href="#" @click.prevent="addresses">Mis direcciones</a>
            <small>Edita globalmente tus direcciones</small>
          </div>
        </div>
      </li-->
    </ul>
  </div>
</template>

<script>

import UserProfile from '../views/UserProfile.vue';
//import MyAddresses from '@linkedcode/address-plugin/src/views/MyAddresses.vue';
import { useSettingsStore } from '../stores/settings';

export default {
  id: 'settings',
  name: 'Settings',
  computed: {
    settings() {
      const store = useSettingsStore();
      return store.items;
    }
  },
  methods: {
    profile() {
      this.$openModal({
        component: UserProfile,
        cssClass: ['open-on-right']
      }).then(function(a) {
        console.log(a);
      });
    },
    openModal(c) {
      this.$openModal({
        component: c
      }).then(function(a) {
        console.log(a);
      });
    },
    /*addresses() {
      this.$openModal({
        component: MyAddresses,
        cssClass: ['open-on-right']
      }).then(function(a) {
        console.log(a);
      });
    }*/
  }
}
</script>

<style>
.big-icon {
  font-size: 2rem;
}
</style>