<template>
  <div class="dropdown">A
    <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
      <img src="https://i.pravatar.cc/32" alt="" class="rounded-circle me-2" width="32" height="32">
      <strong>mdo</strong>
    </a>
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownUser2">
      <li v-if="isAuthed" ><a class="dropdown-item"  href="#" @click.prevent="offcanvas">Perfil</a></li>
      <li v-if="isAuthed" ><hr class="dropdown-divider"></li>
      <li v-if="isAuthed" ><a class="dropdown-item" href="#" @click="logout">Cerrar sesion</a></li>
      <li v-if="!isAuthed" ><a class="dropdown-item" href="#" @click.prevent="login">Ingresar</a></li>
      <li v-if="!isAuthed" ><a class="dropdown-item" href="#" @click.prevent="register">Registrarse</a></li>
    </ul>
  </div>
</template>

<script lang="js">
import UserAppletScript from "../script";
UserAppletScript.name = "UserAppletSidebar";
//console.log(UserAppletScript);
export default UserAppletScript;
</script>