import Listings from "./views/Listings.vue";
//import ListingDetail from "./ListingDetail.vue";
import ListingView from "./views/ListingView.vue";
import ListingEdit from "./views/ListingEdit.vue";
import ListingNew from "./views/ListingNew.vue";

import ListingNewBasic from "./views/ListingNewBasic.vue";

import ListingsOwn from "./views/ListingsOwn.vue";
import LimeHome from "./views/LimeHome.vue";
import QuestionsAsked from "./views/QuestionsAsked.vue";
import QuestionsReceived from "./views/QuestionsReceived.vue";

import ShoppingCart from "./components/ShoppingCart.vue"

import { useListingStore } from "./stores/listings";
/*import QuestionStore from "./stores/question.js";
import ChatStore from "./stores/chat"
import CartStore from "./stores/cart"
import SaleStore from "./stores/sales"
import OrdersStore from "./stores/orders"*/

import ListingCrop from "./components/ListingCrop.vue"

//import { defineAsyncComponent } from 'vue'

const Sales = () => import('./views/Sales.vue')
const Purchases = () => import('./views/Purchases.vue')
const Auctions = () => import('./views/Auctions.vue')

const SalesDetail = () => import('./views/SalesDetail.vue')
const PurchasesDetail = () => import('./views/PurchasesDetail.vue')

const Settings = () => import('./views/Settings.vue')

//const Addresses = () => import('./views/settings/Addresses.vue')

var questionsMenu = {
  title: "Preguntas",
  id: "lime-questions",
  icon: "fas fa-question",
  menu: [
    {
      title: "Recibidas",
      route: "questions-received"
    },
    {
      title: "Hechas",
      route: "questions-asked"
    }
  ]
};

/*    {
      title: "Compras",
      route: "purchases"
    },
    {
      title: "Ventas",
      route: "sales"
    },
*/
var transactionsMenu = {
  title: "Operaciones",
  id: "transactions",
  icon: "fas fa-question",
  menu: [
    {
      title: "Subastas",
      route: "auctions"
    }
  ]
};

export default {
  installed: false,
  install(app) {
    if (this.installed) return;
    this.installed = true;
  },
  name: "Liberamerkato",
  route: "listings",
  component: "Listings",
  menu: [
    {
      title: "Publicaciones",
      id: "lime-listings",
      icon: false,
      menu: [
        {
          title: "Todas las publicaciones",
          name: 'listings-all',
          route: "listings"
        },
        {
          title: "Mis publicaciones",
          name: 'listings-own',
          route: "listings-own"
        }
      ]
    },
    //questionsMenu,
    transactionsMenu
  ],
  routes: [
    {
      path: "/",
      name: "lime",
      component: LimeHome,
      meta: {
        title: "Inicio",
        requiresAuth: false
      }
    },
    {
      path: "/listings",
      name: "listings",
      component: Listings,
      meta: {
        title: "Inicio",
        requiresAuth: false
      }
    },
    {
      path: "/cart",
      name: "cart",
      component: ShoppingCart,
      meta: {
        title: "Cart",
        requiresAuth: true
      }
    },
    {
      path: "/auctions",
      name: "auctions",
      component: Auctions,
      meta: {
        title: "Auctions",
        requiresAuth: true
      }
    },
    {
      name: "listings-add",
      path: "/listings/add",
      component: ListingNew,
      meta: {
        requiresAuth: true,
        title: "Publicar"
      }
    },
    {
      name: "listings-new-basic",
      path: "/listings/new/basic",
      component: ListingNewBasic,
      meta: {
        requiresAuth: true,
        title: "Publicar"
      }
    },
    {
      path: "/listings/:id/edit",
      name: "listings-edit",
      component: ListingEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/listings/:id/upload",
      name: "listing-upload",
      component: ListingCrop,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/listings/own",
      name: "listings-own",
      component: ListingsOwn,
      meta: {
        title: "Inicio",
        requiresAuth: true
      }
    },
    {
      path: "/listings/:id",
      name: "listing-view",
      component: ListingView,
      meta: {}
    },
    {
      path: "/questions",
      name: "questions-received",
      component: QuestionsReceived,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/questions/asked",
      name: "questions-asked",
      component: QuestionsAsked,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/transactions/sales",
      name: "sales",
      component: Sales,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/sales/:id",
      name: "sales-detail",
      component: SalesDetail,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/purchases/:id",
      name: "purchases-detail",
      component: PurchasesDetail,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/transactions/purchases",
      name: "purchases",
      component: Purchases,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      /*children: [{
        path: "addresses",
        name: "settings-addresses",
        component: Addresses
      }],*/
      meta: {
        requiresAuth: true
      }
    }
  ]
};

