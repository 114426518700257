<template>
  <FormControlWrapper :label="label" :errors="errors">
    <textarea :value="modelValue" :class="css" v-bind="$attrs"
      @input="updateModelValue">
    </textarea>
  </FormControlWrapper>
</template>

<script>
import FormControlWrapper from './FormControlWrapper.vue';
import FormValueMixin from './FormValueMixin.vue';

export default {
  name: "FormTextareaWrapper",
  mixins: [FormValueMixin],
  components: {
    FormControlWrapper,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
  }
};

</script>