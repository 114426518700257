<template>
  <div class="container">
    <h2>Preguntar</h2>
    <form novalidate>
      <textarea class="form-control textarea" rows="10" v-model="question"></textarea>
      <div class="button-group">
        <button type="button" @click="cancel()">Cancelar</button>
        <button type="button" class="primary" @click="submit()">Enviar</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "QuestionAsk",
  id: "question-ask",
  props: {
    listingId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      question: "Como va?"
    };
  },
  methods: {
    cancel() {
      this.reject("CERRADO");
    },
    submit() {
      var self = this;
      var data = { listing_id: this.listingId, item: { question: this.question }};
      this.$store.dispatch('questions/ask', data).then(function(response) {
        self.resolve(response.data);
      }).catch(function(err) {
        console.log(err);
      });
    }
  }
};
</script>