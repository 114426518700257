<template>
  <div class="listings">
    <div class="sidebar-widgets">
      <listing-categories v-bind:category="categoryId" @category-changed="setCat"></listing-categories>
      <listings-filters v-model="filters" @changed-filters="setFilters"></listings-filters>
    </div>
    <div class="main-content">
      <lime-breadcrumb v-if="categoryFilter" :tree="breadcrumb" @category-changed="setCat"></lime-breadcrumb>
      <div class="listings-list">
        <listing-item-card v-for="listing in items" v-bind:key="listing.id" v-bind:item="listing">
        </listing-item-card>
      </div>
    </div>
  </div>
</template>

<script>
import BootstrapPagination from '../components/BootstrapPagination.vue';
import LimeBreadcrumb from '../components/LimeBreadcrumb.vue';
import ListingCategories from '../components/ListingCategories.vue';
//import ListingItem from "./ListingItem.vue";
import ListingItemCard from "../components/ListingItemCard.vue";
import ListingsFilters from "../components/ListingsFilters.vue";
import { useListingStore } from '../stores/listings';

export default {
  name: "Listings",
  data: function() {
    var p = "1";

    if (this.$route.query.p) {
      p = this.$route.query.p;
    }

    return {
      filters: {},
      categoryId: 0,
      grid: false,
      pagina: p,
      params: {
        'p': p
      }
    };
  },
  mounted() {
  },
  computed: {
    store() {
      return useListingStore(this.$pinia);
    },
    items: function() {
      return this.store.all;
    },
    categoryFilter() {
      return this.store.categories;
    },
    breadcrumb() {
      if (this.store.filters.category) {
        return this.store.filters.category.breadcrumb;
      } else {
        return [];
      }
    }
  },
  components: {
    ListingItemCard,
    ListingsFilters,
    ListingCategories,
    LimeBreadcrumb,
    BootstrapPagination
  },
  watch: {
    filters(to, from) {
      console.log("filters", to, from)
    },
    $route(to, from) {
      if (from.name != to.name) {
        return;
      }

      if (to.query.cat) {
        //
      } else {
        const store = useListingStore();
        store.clearCategory();
      }
      this.search();
    }
  },
  methods: {
    setCat(id) {
      console.log('setCat', id);
      this.categoryId = id;
      this.updateRoute(Object.assign({}, this.$route.query, { cat: id}));
    },
    setFilters(f) {
      console.log('setFilters', f);
      //this.filters = f;
      this.updateRoute(f);
    },
    pageChange(info) {
      console.log("pageChange", info);
      //this.$emit('pageChanged', info);
      this.params.page = info.pageNumber;
    },
    updateRoute(params) {
      let q = Object.assign({}, params);
      if (q.p && parseInt(q.p) === 1) {
        delete q.p;
      }
      if (q.cat && parseInt(q.cat) === 0) {
        delete q.cat;
      }
      this.$router.push({ name: 'listings', query: q });
    },
    search() {
      let q = Object.assign({}, this.$route.query, this.params, this.filters);
      if (q.p && parseInt(q.p) === 1) {
        delete q.p;
      }
      if (q.cat && parseInt(q.cat) === 0) {
        delete q.cat;
      }
      this.$router.push({ name: 'listings', query: q });
      
      console.log('search', q);
      const store = useListingStore();
      //this.$store.dispatch('listings/search', q);
      store.search(q);
    }
  },
  beforeRouteUpdate(to, from) {
    // react to route changes...
    //this.userData = await fetchUser(to.params.id)
    //console.log(to, from);
  },
  mounted: function() {
    //this.$refs.page.pageSize = 30;
    //var query = this.$route.query;
    //this.$store.dispatch('listings/search', query);
    this.search();
  }
};
</script>
<style>
aside h2 {
  font-size: 1.25rem;
  font-weight: bold;
}
</style>