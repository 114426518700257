<template>
  <fieldset>
    <legend>{{ legend }}</legend>
    <slot></slot>
  </fieldset>
</template>

<script lang="js">

export default {
  name: "FormFieldsetWrapper",
  props: {
    legend: {
      type: String,
      required: true,
    },
  },
};
</script>
