<script>
export default {
  data() {
    return {
      errors: [],
      apiErrors: [],
    };
  },
  props: {
    /*apiErrors: {
      type: Array,
      default: [],
    },*/
    invalid: {
      type: Array,
      required: false,
      default: [],
    },
    modelValue: {
      type: [String, Number],
      default: "",
    }
  },
  emits: ["update:modelValue", 'changed'],
  computed: {
    css() {
      return { 
        error: (this.errors.length > 0 || this.apiErrors.length > 0),
        valid: this.errors.length === 0 && this.apiErrors.length === 0,
      };
    }
  },
  methods: {
    resetErrors() {
      this.apiErrors = [];
      this.errors = [];
    },
    updateApiErrors(apiError) {
      console.log('updateApiErrors, adding', apiError);
      this.apiErrors.push(apiError);
    },
    updateModelValue($event) {
      //console.log('validating', 'new value', $event.target.value);
      this.resetErrors();
      this.$emit('update:modelValue', $event.target.value);
      //this.$emit('changed', $event.target.value);
      this._validate($event.target.value);
    },
    validateRequired(attr, value) {
      //console.log(typeof attr);
      //console.log(typeof value);
      //console.log('validateRequired', attr, value);
      var returnValue = true;
      if (attr === "true") {
        if (typeof value === "number") {
          if (value === 0) {
            returnValue = false;
          }
        } else if (typeof value === "string") {
          if (value === "") {
            returnValue = false;
          }
          if (value === "0") {
            returnValue = false;
          }
        } else {
          console.warn("Falta programar");
        }
      }

      if (returnValue === false) {
        this.errors.push("Debe seleccionar algun valor");
      } else if (this.invalid.length > 0) {
        if (this.invalid.includes(value)) {
          console.log('Invalido por invalid.includes');
          this.errors.push("Debe seleccionar algun valor");
        }
      }
    },
    findRefByName(refName) {
      let obj = this
      while (obj) {
        if (obj.$refs[refName]) {
          return obj.$refs[refName]
        }
        obj = obj.$parent
      }
      return undefined
    },
    validate() {
      this.resetErrors();
      this._validate(this.modelValue);
      return this.isValid();
    },
    isValid() {
      return this.errors.length === 0;
    },
    _validate(value) {
      for (var attr in this.$attrs) {
        //console.log('attr', attr);
        switch (attr) {
          case 'required': {
            this.validateRequired(this.$attrs[attr], value);
          }
        }
      }

      if (typeof this.$attrs.maxlength != "undefined") {
        //console.log("maxlength", this.$attrs.maxlength, this.modelValue);
        if (value.length > this.$attrs.maxlength) {
          this.errors.push("Longitud maxima excedida: " + this.$attrs.maxlength);
        }
      }
      if (typeof this.$attrs.minlength != "undefined") {
        //console.log("minlength", this.$attrs.minlength, this.modelValue);
        if (value.length < this.$attrs.minlength) {
          this.errors.push("Longitud minima no alcanzada: " + this.$attrs.minlength);
        }
      }
      if (typeof this.$attrs.required != "undefined") {
      }
    },
  },
}
</script>